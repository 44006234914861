<app-navbar-style-one></app-navbar-style-one>
<app-navbar-style-two></app-navbar-style-two>

<section class="profile-authentication-area ptb-20">
    <div class="container">
        <div class="row">
            
            <div class="col-lg-5 col-md-12 col-sm-12">
                <div class="login-form">
                    <h2 style="text-align: center;">Login</h2>
        
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)" style="padding-left: 10px;padding-right: 10px;">
                        <div class="form-group">
                            <label>Email</label>
                            <input required minlength="3" maxlength="50" ngModel name="email" type="email" #email="ngModel" class="form-control" id="email" placeholder="Enter Email">
                        </div>
        
                        <div class="form-group">
                            <label>Password</label>
                            <input required ngModel name="password" type="password" #password="ngModel" class="form-control" id="password" placeholder="Enter Password">
                        </div>
        
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-6 col-sm-6 remember-me-wrap">
                                <p>
                                    <input type="checkbox" id="test2">
                                    <label for="test2">Remember me</label>
                                </p>
                            </div>
        
                            <div class="col-lg-6 col-md-6 col-sm-6 lost-your-password-wrap">
                                <a  (click)="lostPassword()" class="lost-your-password">Forgot your password?</a>
                            </div>
                        </div>
        
                        <button type="submit">LOG IN</button>
                    </form>
                    <br>
                    
                    <form #contactForm="ngForm" (ngSubmit)="submitLostPassword(contactForm.value)" style="padding-left: 10px;padding-right: 10px;" *ngIf="lostPasswordCliked">
                        <h2 style="text-align: center;">Forgot Password</h2>
                        <div class="form-group">
                            <label>Enter the Email to reset your password *</label>
                            <input required minlength="3" maxlength="50" ngModel name="email" type="email" #email="ngModel" class="form-control" id="email" placeholder="Enter Email">
                        </div>
        
                        <button type="submit">RESET</button>
                    </form>

                  
                </div>
            </div>
            <div class="col-lg-1 col-md-12 col-sm-12">
                <h3 class="or">OR</h3>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="login-form">
                    <h2 style="text-align: center;">Register</h2>

                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)" style="padding-left: 10px;padding-right: 10px;">
                        <div class="form-group">
                            <label>Username</label>
                            <input required minlength="3" maxlength="50" ngModel name="username" type="text" #username="ngModel" class="form-control" id="username" placeholder="Enter Username">
                        </div>

                        <div class="form-group">
                            <label>Email</label>
                            <input required minlength="3" maxlength="50" ngModel name="email" type="email" #email="ngModel" class="form-control" id="email" placeholder="Enter Email">
                        </div>

                        <div class="form-group">
                            <label>Password</label>
                            <input required minlength="3" maxlength="50" ngModel name="password" type="password" #password="ngModel" class="form-control" id="password" placeholder="Enter Password">
                        </div>

                        <div class="form-group">
                            <label>Confirm Password</label>
                            <input required minlength="3" maxlength="50" ngModel name="confirmpassword" type="password" #confirmpassword="ngModel" class="form-control" id="confirmpassword" placeholder="Confirm Password">
                        </div>

                        <!--p class="description">The password should be at least eight characters long. To make it stronger, use upper and lower case letters, numbers, and symbols like ! " ? $ % ^ & )</p-->

                        <button type="submit">REGISTER</button>
                    </form>
                </div>
            </div>
        </div>
    
    </div>
</section>
<!--section class="profile-authentication-area ptb-20">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-2 col-sm-2"></div>
            <div class="col-lg-6 col-md-8 col-sm-8">
                <div class="login-form">
                    <h2 style="text-align: center;">Login</h2>
        
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)" style="padding-left: 10px;padding-right: 10px;">
                        <div class="form-group">
                            <label>Email</label>
                            <input required minlength="3" maxlength="50" ngModel name="email" type="email" #email="ngModel" class="form-control" id="email" placeholder="Enter Email">
                        </div>
        
                        <div class="form-group">
                            <label>Password</label>
                            <input required ngModel name="password" type="password" #password="ngModel" class="form-control" id="password" placeholder="Enter Password">
                        </div>
        
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-6 col-sm-6 remember-me-wrap">
                                <p>
                                    <input type="checkbox" id="test2">
                                    <label for="test2">Remember me</label>
                                </p>
                            </div>
        
                            <div class="col-lg-6 col-md-6 col-sm-6 lost-your-password-wrap">
                                <a routerLink="/" class="lost-your-password">Lost your password?</a>
                            </div>
                        </div>
        
                        <button type="submit">LOG IN</button>
                    </form>
                    <h3 style="text-align: center;">OR</h3>
                    <h2 style="text-align: center;"> <a routerLink="/register" class="lost-your-password">Register</a></h2>
                </div>
            </div>
            <div class="col-lg-3 col-md-2 col-sm-2"></div>
        </div>
    
    </div>
</section-->
