<div class="tab-pane fade active show" id="tab-account" role="tabpanel" aria-labelledby="tab-account-link">
    <form method="POST">
        <div class="row mb-5">
            <div class="col-sm-6">
                <label>First Name *</label>
                <input type="text" class="form-control" [(ngModel)]="user.first_name" value="" id="acoount-first-name" name="acoount-first-name" required="">
            </div><!-- End .col-sm-6 -->

      

            <div class="col-sm-6">
                <label>Last Name *</label>
                <input type="text" class="form-control" [(ngModel)]="user.last_name" value="" id="acoount-last-name" name="acoount-last-name" required="">
            </div><!-- End .col-sm-6 -->
        </div><!-- End .row -->

        <div class="row mb-5" >
            <label>Email address *</label>
            <input type="email" class="form-control" readonly=""  [(ngModel)]="user.email" id="acoount-email" name="acoount-email" required="">
    
        </div>



        <div class="row mb-5">
            <label id="resend_code">Mobile Number   <span id="verify_notice" style="color: red; " *ngIf="user.otpVerifyed!=1" > not verified</span>    *  <span id="countdown"></span> <a href="#" id="resend_code_a" style="display: none;"> Resend OPT </a> </label>
              
            <div class="col-12">

                <div class="row">
                    <div class="col-2">
                        <select id="acoount-display-mobile-code" class="form-control" aria-label="Default select example">
                            <option selected="" value="+94">+94</option>
                        </select>
                    </div>
                    <div class="col-4">
                        <input type="text" value="1" [(ngModel)]="user.mobile" class="form-control" id="acoount-mobile-number" name="acoount-mobile-number" required="">
                        
                    </div>
                    <div class="col-3">

                        <button type="submit" class="btn btn-outline-success" (click)="sendOTPNumber()" id="save_changers" name="save_changers">
                            <span>SEND CODE</span>
                            <i class="icon-long-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </div><!-- End .col-sm-6 -->
            <br>
            <div class="col-sm-3" id="verify_otp_div" *ngIf="showVerifyOtpDiv">
                <label id="mobile_number_ver_lable">Verification Code</label>
                <input type="text" class="form-control" [(ngModel)]="verificationCode" id="opt-verifiy-code" name="opt-verifiy-code" required="">

            </div><!-- End .col-sm-6 -->
            <div class="col-sm-3" id="verify_otp_button_div" *ngIf="showVerifyOtpDiv">
                <br>
                <button (click)="verifyMobileNumber()" type="submit" class="btn btn-outline-success" id="verify-otp" name="verify-otp">
                    <span>Verify OTP</span>
                    <i class="icon-long-arrow-right"></i>
                </button>
            </div><!-- End .col-sm-6 -->
        </div><!-- End .row -->


        <button (click)="saveChanges()" type="submit" class="btn btn-outline-success" id="save_changers" name="save_changers">
            <span>SAVE CHANGES</span>
            <i class="icon-long-arrow-right"></i>
        </button>
    </form>
</div>