import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ProductServiceService } from '../../admin_service/product-service.service';

@Component({
    selector: 'app-shop-right-sidebar-page-two',
    templateUrl: './shop-right-sidebar-page-two.component.html',
    styleUrls: ['./shop-right-sidebar-page-two.component.scss']
})
export class ShopRightSidebarPageTwoComponent implements OnInit {

    constructor(private productService:ProductServiceService) { }

    ngOnInit(): void {
        this.resetOption = [this.options[0]];
    }

    docId:any;
    submit(form) {
        var productTitle = form.productTitle;
        var productDesciption = form.productDesciption;
        let details = {
            'id': "0",
            'status': 'NEW',
            'title': productTitle,
            'shortDescription': productDesciption,
            'detailsLink': 'software_product_details/',
            'category':'Software',
            'otherImages': [],
        }

        const uploadFormData = new FormData();
        uploadFormData.append('details', JSON.stringify(details));
        this.productService.saveSoftwareDetails(uploadFormData).subscribe(res => {
            var status = res.status;
            this.docId = res.doc_id;
            if (status == 'success') {
                Swal.fire({
                    title: 'Successfully added the product!',
                }).then((result) => {
                })
            } else {
                Swal.fire({
                    title: 'Error with adding the product!',
                }).then((result) => {

                })
            }
        })
    }


    public selectedMainImage: any = File;
    onFileSelectedMainImg(event: any) {
        const file = event.target.files[0];
        this.selectedMainImage = file;
    }

    saveMainImage(fileInput: HTMLInputElement) {
        const uploadFormData = new FormData();
        uploadFormData.append('file', this.selectedMainImage);

        if (this.docId.length == 0) {
            Swal.fire({
                title: 'Please save product details to add images!',
            }).then((result) => {
            })
        } else {
            this.productService.uploadSoftwareMainImage(uploadFormData, this.docId).subscribe(res => {
                var status = res.status;
                if (status == 'success') {
                    Swal.fire({
                        title: 'Successfully added the product main image!',
                    }).then((result) => {
                        fileInput.value = '';
                        // location.reload();
                    })
                } else {
                    Swal.fire({
                        title: 'Error with adding the product!',
                    }).then((result) => {
                        fileInput.value = '';
                        //location.reload();
                    })
                }
            })
        }

    }
    //***********Other Images ***************************** */
    public otherImages: any = File;
    otherImagesList: Array<any> = [];
    otherImagesNameList: Array<any> = [];
    onFileSelectedOtherImages(event: any) {
        const file = event.target.files[0];
        this.otherImages = file;
    }

    addImagesOtherToList(fileInput: HTMLInputElement) {
        const uploadFormData = new FormData();
        uploadFormData.append('file', this.otherImages);

        this.productService.uploadSoftwareOtherImage(uploadFormData, this.docId).subscribe(res => {
            var status = res.status;
            if (status == 'success') {
                Swal.fire({
                    title: 'Successfully added the product image!',
                }).then((result) => {
                    fileInput.value = '';
                })
            } else {
                Swal.fire({
                    title: 'Error with adding the product!',
                }).then((result) => {
                    fileInput.value = '';
                })
            }
        })

    }

    pageTitle = [
        {
            bgImage: 'assets/img/page-title-bg.jpg',
            title: 'Shop Left Sidebar'
        }
    ]
    singleProductsItem = [
        {
            mainImg: 'assets/img/products/products4.jpg',
            title: 'Ergonomic Desk Sofa',
            newPrice: '$150.00',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products5.jpg',
            title: 'Office Desk Sofa',
            newPrice: '$199.00',
            oldPrice: '$210.00',
            sale: 'Sale!',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products6.jpg',
            title: 'Swivel Sofa',
            newPrice: '$223.99',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products7.jpg',
            title: 'Home Alisa Sofa',
            newPrice: '$199.00',
            oldPrice: '$210.00',
            sale: '',
            outOfStock: 'Out of Stock',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products8.jpg',
            title: 'Grey Harrington Sofa',
            newPrice: '$199.00',
            oldPrice: '$210.00',
            sale: 'Sale!',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products9.jpg',
            title: 'Home Alisa Sofa',
            newPrice: '$223.99',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products10.jpg',
            title: 'Wood Patio Chair',
            newPrice: '$226.00',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products11.jpg',
            title: 'Brown Vinyl Padded',
            newPrice: '$107.99',
            oldPrice: '$140.99',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products12.jpg',
            title: 'Antique Walnut',
            newPrice: '$98.99',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products6.jpg',
            title: 'Swivel Sofa',
            newPrice: '$223.99',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products7.jpg',
            title: 'Home Alisa Sofa',
            newPrice: '$199.00',
            oldPrice: '$210.00',
            sale: '',
            outOfStock: 'Out of Stock',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products8.jpg',
            title: 'Grey Harrington Sofa',
            newPrice: '$199.00',
            oldPrice: '$210.00',
            sale: 'Sale!',
            outOfStock: '',
            detailsLink: 'simple-product'
        }
    ]

    // For Pagination
    shopRightSidebar: number = 1;

    // Category Select
    singleSelect: any = [];
    multiSelect: any = [];
    stringArray: any = [];
    objectsArray: any = [];
    resetOption: any;
    config = {
        displayKey: "name",
        search: true
    };
    options = [
        {
            name: "Default",
        },
        {
            name: "Popularity",
        },
        {
            name: "Latest",
        },
        {
            name: "Price: low to high",
        },
        {
            name: "Price: high to low",
        }
    ];
    searchChange($event) {
        console.log($event);
    }
    reset() {
        this.resetOption = [];
    }

}