import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-subscribe',
    templateUrl: './subscribe.component.html',
    styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    subscribeArea = [
        {
            bgImage: '',
            subTitle: '',
            title: 'Register for TeDi Newsletter'
        }
    ]

}