import { Component } from '@angular/core';

@Component({
  selector: 'app-gift-card',
  standalone: true,
  imports: [],
  templateUrl: './gift-card.component.html',
  styleUrl: './gift-card.component.scss'
})
export class GiftCardComponent {

}
