import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { UserAddressService } from 'src/app/components/common_service/user-address.service';
import { UserAddress } from './UserAddress';
import { FormsModule } from '@angular/forms';
import Swal from 'sweetalert2';
declare var bootstrap: any;


@Component({
  selector: 'app-addresses',
  standalone: true,
  imports: [CommonModule,
    FormsModule
  ],
  templateUrl: './addresses.component.html',
  styleUrl: './addresses.component.scss'
})
export class AddressesComponent {


  constructor(private userAddressService: UserAddressService ){
    this.setPostalCode();
    this.listUserAddress();
  }



  postalCodes = []
  setPostalCode(){

    this.userAddressService.getAddressPostalCode().subscribe(res=>{
      this.postalCodes = res.data;
    });

  }

  userAddress: UserAddress = {
    id:0,
    type: 'Billing Address', // default value
    addressLineOne: '',
    addressLineTwo: '',
    addressLineThree: '',
    city: '',
    country: 'LK', // default value for the country
    postalCode: ''
  };


  createNewAddress(){
    this.userAddress = {
      id:0,
      type: 'Billing Address', // default value
      addressLineOne: '',
      addressLineTwo: '',
      addressLineThree: '',
      city: '',
      country: 'LK', // default value for the country
      postalCode: ''
    };
  }




  selectedPostalCode: string = '';
  onCitySelect(postalCode: string): void {
    this.userAddress.postalCode = postalCode;
    this.selectedPostalCode = postalCode;
  }


  onSubmit(): void {
    console.info(this.userAddress);
    this.userAddressService.saveAddress(this.userAddress).subscribe(res=>{
      if(res.status=='Success'){
        Swal.fire({
          title: 'Success',
          text: 'The address has been saved successfully!',
          icon: 'success',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            // You can add any additional logic here if needed
          }
        });
        
        const modalElement = document.getElementById('addAddressModal');
        if (modalElement) {
          const modal = bootstrap.Modal.getInstance(modalElement);
          modal.hide();
        }
        this.listUserAddress();
        this.createNewAddress();
      }else{
        Swal.fire({
          title: 'Save Failed',
          text: 'Unable to save the address. Please try again later.',
          icon: 'error',
          confirmButtonText: 'Retry'
        }).then((result) => {
          if (result.isConfirmed) {
            // You can add retry logic or other actions here if needed
          }
        });
      }
    })
  }



  userAddressList:UserAddress[] = []
  listUserAddress(){
    this.userAddressService.findByUser().subscribe(res=>{
        this.userAddressList = res.data;
 
    })
  }



  onEditAddress(address: any): void {
    // Set selected address data to the form
    this.userAddress = { ...address };

    // Open the modal
    const modal = new bootstrap.Modal(document.getElementById('addAddressModal'));
    modal.show();
  }


  deleteAddress(id: any) {
    // Show a confirmation prompt before deleting
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this address? This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.isConfirmed) {
        // If the user confirms, proceed with deletion
        this.userAddressService.delete(id).subscribe(res => {
          if (res.status == 'Success') {
            Swal.fire({
              title: 'Deleted',
              text: 'The address has been deleted successfully!',
              icon: 'success',
              confirmButtonText: 'OK'
            }).then(() => {
              // Refresh the address list after successful deletion
              this.listUserAddress();
            });
          } else {
            Swal.fire({
              title: 'Delete Failed',
              text: 'Unable to delete the address. Please try again later.',
              icon: 'error',
              confirmButtonText: 'Retry'
            });
          }
        });
      }
    });
  }
  


}
