

<div class="modal fade" id="addAddressModal" tabindex="-1" aria-labelledby="addAddressModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="container">
                <!-- Close Button -->
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12" style="text-align: right;">
                        <button type="button" class="close2" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><i class='bx bx-x' style="font-size:28px;"></i></span>
                        </button>
                    </div>
                </div>

                <!-- Address Form -->
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="address-form">
                            <h2 style="text-align: center; color: #a6c76c; border-bottom-color: #a6c76c;">Add New Address</h2>
                            <hr style="text-align: center; color: #a6c76c; border-bottom-color: #a6c76c;height: 2px; background-color: #a6c76c; " >
                            <form  style="padding-left: 5%; padding-right: 5%;">
                                <div class="form-group">
                                    <label for="address-line1">Address Line 1 *</label>
                                    <input 
                                        required minlength="3" maxlength="50" 
                                         name="addressLine1" 
                                        type="text" 
                                        [(ngModel)]="userAddress.addressLineOne"
                                        [ngModelOptions]="{standalone: true}"
                                        class="form-control" id="address-line1" 
                                        placeholder="Enter Address Line 1">
                                </div>
                                <div class="form-group">
                                    <label for="address-line2">Address Line 2</label>
                                    <input 
                                        minlength="3" maxlength="50" 
                                         name="addressLine2" 
                                         [(ngModel)]="userAddress.addressLineTwo"
                                         [ngModelOptions]="{standalone: true}"
                                        type="text"
                                        class="form-control" id="address-line2" 
                                        placeholder="Enter Address Line 2">
                                </div>
                                <div class="form-group">
                                    <label for="address-line3">Address Line 3</label>
                                    <input 
                                        minlength="3" maxlength="50" 
                                         name="addressLine3" 
                                         [(ngModel)]="userAddress.addressLineThree"
                                         [ngModelOptions]="{standalone: true}"
                                        type="text" 
                                        class="form-control" id="address-line3" 
                                        placeholder="Enter Address Line 3">
                                </div>
                                <div class="form-group">
                                    <label for="city">City *</label>

                                    

                                    <select  class="form-control" id="city"  [(ngModel)]="userAddress.postalCode"  [ngModelOptions]="{standalone: true}"  (change)="onCitySelect($event.target.value )" >
                                        <option value="" selected>Select City</option>
                                        <option *ngFor="let code of postalCodes"   [value]="code.postalCode"  > {{code.cityName}} </option>
                                    </select>
                                    
                                </div>
                                <div class="form-group">
                                    <label for="postalCode">Postal Code *</label>
                                    <input 
                                        required minlength="3" maxlength="10" 
                                         name="postalCode" 
                                         [(ngModel)]="userAddress.postalCode"
                                         [ngModelOptions]="{standalone: true}"
                                        type="text" 
                                        readonly
                                        [value]="selectedPostalCode"
                                        class="form-control" id="postalCode" 
                                        placeholder="Enter Postal Code">
                                </div>

                                <div class="form-group">
                                    <label for="type">Address Type *</label>
                                    <select  class="form-control" id="type"  [(ngModel)]="userAddress.type"  [ngModelOptions]="{standalone: true}" >
                                        <option value="Billing Address" selected>Billing Address</option>
                                        <option value="Shipping Address">Shipping Address</option>
                                    </select>
                                </div>
 
                                <!-- Submit Button -->
                                <button type="submit" (click)="onSubmit()" id="address_button" class="btn btn-outline-success">
                                    <span>Add Address</span>
                                    <i class="icon-long-arrow-right"></i>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
                <br><br>
            </div>
        </div>
    </div>
</div>



<p>The addresses listed below will be used by default on the checkout page. <br>
    To add a new address, <span><a href="#" data-bs-toggle="modal"
        data-bs-target="#addAddressModal" (click)="createNewAddress()" >click here</a></span>.</p>

<br>

<table class="table">
    <thead>
      <tr>
        <th scope="col" style="width: 70%;">Address</th>
        <th scope="col">Type</th>
        <th scope="col">Action</th>

      </tr>
    </thead>
    <tbody>
      
        <tr *ngFor="let address of userAddressList"  >
            <td> {{address.addressLineOne}}  {{address.addressLineTwo}} {{address.addressLineThree}} {{address.city}}   {{address.postalCode}}  </td>
            <td>  {{address.type}}  </td>
            <td> 
                <i class='bx bx-edit' style="font-size: 18px; color: #a6c76c; cursor: pointer;"  (click)="onEditAddress(address)" ></i> 
                <i class='bx bx-trash' style="font-size: 18px; color: #a6c76c;cursor: pointer;" (click)="deleteAddress(address.id)" ></i>  </td>
        </tr>

    </tbody>
  </table>
