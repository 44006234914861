import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackendURLService } from '../admin_service/backend-url.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerRegistrationService {

  constructor(private httpClient:HttpClient,private backendService:BackendURLService) { }

  customerRegister(details:any){
    let token = localStorage.getItem('token');
    return this.httpClient.post<any>(this.backendService.apiCustomerLocalUrl+'customer/registertion?mds='+token,details);
  }

  sendEmailVeirificationCode(details:any){
    let token = localStorage.getItem('token');
    return this.httpClient.post<any>(this.backendService.apiCustomerLocalUrl+'customer/request/email-code?mds='+token,details);

  }

  verifyEmailCode(details:any){
    return this.httpClient.post<any>(this.backendService.apiCustomerLocalUrl+'customer/verify/email-code',details);
  }

  sendMobileVeirificationCode(details:any){
    let token = localStorage.getItem('token');
    return this.httpClient.post<any>(this.backendService.apiCustomerLocalUrl+'customer/request/mobile-code?mds='+token,details);

  }

  verifyMobileCode(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'customer/verify/mobile-code',details);
  }
}
