import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { UserloginService } from '../../common_service/userlogin.service';
import { PaymentService } from '../../common_service/payment.service';

@Component({
    selector: 'app-gallery-page',
    templateUrl: './gallery-page.component.html',
    styleUrls: ['./gallery-page.component.scss']
})
export class GalleryPageComponent implements OnInit {

    constructor(private userlogin:UserloginService,private paymentService:PaymentService) { }

    activeLink:string='orders';
    type:string = "Order";
    setActive(link: string) {
        this.activeLink = link;
      }

    isActive(item: string): boolean {
        return this.activeLink === item;
    }

    showThis(type:any){
        this.activeLink=type

        if(type=='LogOut'){
                localStorage.setItem('token','empty');
                localStorage.setItem('accShow','notShow');
               window.location.href="/";
             
        }
    }

    billingAddressObj:any;
    shippingAddressObj:any;
    userName:any;
    emailval:any;
    preOrderList:any;
    preOrderListSize:any;
    ngOnInit(): void {
      let token = localStorage.getItem('token');
      if(token==null || token=='null'){
        Swal.fire({
            title: 'Please log into the system!',
          }).then((result) => {
            
          })
      }else{
   

        /*
        this.userlogin.getUserDetails(uploadFormData).subscribe(res=>{
            let status = res.status;
            if(status=='success'){
                let userDetails = res.user_data;
                this.userName = userDetails.username;
                this.emailval = userDetails.email;
            }
        })
        this.userlogin.getUserDetails(uploadFormData).subscribe(res=>{
            let status = res.status;
            if(status=='success'){
                let userDetails = res.user_data;
                this.userName = userDetails.username;
                this.emailval = userDetails.email;
            }
        })
*/




      }
    }

    pageTitle = [
        {
            bgImage: 'assets/img/page-title-bg.jpg',
            title: 'Order Tracking'
        }
    ]

    submit(form){
        var email = form.email;
        console.log(email);
        
        var password = form.password;
        console.log(password);

        let details = {
            'email':email,
            'password':password,  
        }
        const uploadFormData = new FormData();
        uploadFormData.append('details', JSON.stringify(details));
       
    }



    signOut() {
        // Clear all local storage
        localStorage.clear();
    
        // Optionally, you can navigate to a different route if needed
        // this.router.navigate(['/login']); // Uncomment if using Angular Router
      }

}