<app-navbar-style-one></app-navbar-style-one>
<app-navbar-style-two></app-navbar-style-two>

<section class="profile-authentication-area ptb-20">
    <div class="container">
        <div class="row">
            <div class="col-3"></div>
            <div class="col-6">
                <div class="login-form">
                    <h2 style="text-align: center;">Edit Address</h2>

                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                        <div class="form-group">
                            <label>Address Line 1 <span>*</span></label>
                            <input required minlength="3" maxlength="50" ngModel name="line1" type="text" [ngModel]="addressDetails.line1" class="form-control" id="line1" placeholder="Enter Address Line 1" required>
                        </div>

                        <div class="form-group">
                            <label>Address Line 2 <span>*</span></label>
                            <input required minlength="3" maxlength="50" ngModel name="line2" type="text" [ngModel]="addressDetails.line2" class="form-control" id="line2" placeholder="Enter Address Line 2" required>
                        </div>

                        <div class="form-group">
                            <label>Address line 3</label>
                            <input required minlength="3" maxlength="50" ngModel name="line3" type="text" [ngModel]="addressDetails.line3" class="form-control" id="line3" placeholder="Enter Address Line 3">
                        </div>

                        <div class="form-group">
                            <label>City <span>*</span></label>
                            <input required minlength="3" maxlength="50" ngModel name="city" type="text" [ngModel]="addressDetails.city" class="form-control" id="city" placeholder="Enter City">
                        </div>

                        <div class="form-group">
                            <label>Postal Code <span>*</span></label>
                            <input required minlength="3" maxlength="50" ngModel name="postalCode" type="text" [ngModel]="addressDetails.postalCode" class="form-control" id="postalCode" placeholder="Enter Postal Code">
                        </div>

                        <div class="form-group">
                            <label>Country <span>*</span></label>
                            <input required minlength="3" maxlength="50" ngModel name="country" type="text" [ngModel]="addressDetails.country" class="form-control" id="country" placeholder="Enter Country">
                        </div>
                        <button type="submit">UPDATE</button>
                    </form>
                </div>
            </div>
            <div class="col-3"></div>
        </div>
        
    </div>
</section>
