import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackendURLService } from '../admin_service/backend-url.service';

@Injectable({
  providedIn: 'root'
})
export class BannersService {

  constructor(private httpClient:HttpClient,private backendService:BackendURLService) { }

  getBanners(type:any){
    return this.httpClient.get<any>(this.backendService.apiLocalUrl+'banners/home_banners?type='+type);

  }
}
