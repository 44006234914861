import { Component, OnInit } from '@angular/core';
import { UserloginService } from '../../common_service/userlogin.service';
import { AddToCartService } from '../../common_service/add-to-cart.service';
import { CheckoutService } from '../../common_service/checkout.service';
import Swal from 'sweetalert2';
import { EMPTY } from 'rxjs';
import { UserAddressService } from '../../common_service/user-address.service';
import { UserAddress } from '../gallery-page/addresses/UserAddress';
import { Router } from '@angular/router';
import { PaymentDetails } from './PaymentDetails ';
declare var Checkout: any;
@Component({
  selector: 'app-checkout-page',
  templateUrl: './checkout-page.component.html',
  styleUrls: ['./checkout-page.component.scss']
})
export class CheckoutPageComponent implements OnInit {

  userCartDetailsList: Array<any> = [];
  subTotalData: number = 0;
  shipping: number = 0;
  allTotal_value: number = 0;
  discount:number = 0;
  orderId:any;

  termsAccepted: boolean = false;
  selectedPaymentType: string = 'card'; // Set default value as 'card'


  onTermsAcceptedChange(event: Event): void {
    this.termsAccepted = (event.target as HTMLInputElement).checked;
    console.log('Terms Accepted:', this.termsAccepted);
  }

  constructor(private cartService: AddToCartService, private loginService: UserloginService, private checkoutService: CheckoutService, private userAddressService: UserAddressService, private router: Router) {
    this.listUserAddress();

    /*

    let token = localStorage.getItem("token");
    let details = {
      'token': token,
    }
    const uploadFormData = new FormData();
    uploadFormData.append('details', JSON.stringify(details));
    this.checkoutService.getOrderDetails(uploadFormData).subscribe(res => {
      let status = res.status;
      console.log("this.userCartDetailsList 2 " + JSON.stringify(res));
      if (status == "Success") {
        this.userCartDetailsList = res.data;

        for (let i = 0; i < this.userCartDetailsList.length; i++) {
          let item = this.userCartDetailsList[i];
          this.subTotalData =  item.price;

      }

        this.allTotal_value = this.subTotalData+this.shipping;

        this.orderId = res.order_id;
        let discountD = {
          'discount_amount':0
        }

        let vouchers = {
          'vouchers':''
        }
        let details2 = {
          'token': token,
          'order_id': this.orderId,
          'amount': res.total_checkout,
          'description': 'Place_Order',
          'discount':discountD,
          'vouchers':vouchers

        }
        console.log("details2 "+JSON.stringify(details2))
        const uploadFormData = new FormData();
        uploadFormData.append('details', JSON.stringify(details2));
        this.checkoutService.getCheckoutToken(uploadFormData).subscribe(res => {
          console.log("getCheckoutToken "+JSON.stringify(res))
          let st = res.status;
          if (st == "success") {
            this.sessionToken = res.sessionToken;
            this.orderId= res.order_id;
          }

        })
      } else {
        Swal.fire({
          title: 'No items in the cart!',
        }).then((result) => {

        })
      }

    })


*/

  }

  paymentType: any;
  handleClick(type: any) {
    this.paymentType = type;
    console.log("clik" + type);
  }


  billingAddressObj: any;
  shippingAddressObj: any;
  billingAddressObjSize: number = 0;
  shippingAddressObjSize: number = 0;
  ngOnInit(): void {
    let token = localStorage.getItem('token');
    if (token == null || token == 'null') {
      Swal.fire({
        title: 'Please log into the system!',
      }).then((result) => {

      })
    } else {
      let details = {
        'token': token,
      }

    }
  }

  pageTitle = [
    {
      bgImage: 'https://tedismart.com/assets/images/TopHeaderAnimation.gif',
      title: 'Checkout'
    }
  ]


  searchChange($event) {
    console.log($event);
  }

  selectedAddressShipping:string='';
  selectedShippingAddres(selectedAddressShipping:any){
    this.selectedAddressShipping = selectedAddressShipping;
    console.log("selectedAddressShipping "+JSON.stringify(selectedAddressShipping));
  }





  paymentDetails:PaymentDetails ={
    payType : this.selectedPaymentType,

    
  }
  //Open payment gateway
  sessionToken: any;
  
  openGatway() {


    this.paymentDetails = {
      payType : this.selectedPaymentType,
      billingAddress: this.billingAddress,
      shippingAddress: this.shippingAddress
    }


    this.checkoutService.getPaymentToken(this.paymentDetails).subscribe(res => {
      let status = res.status;
      if(status=='Success'){
        Checkout.configure({
          session: {
            id: res.data.sessionId,
          },
          interaction: {
            merchant: {
              logo: 'https://tedismart.com/assets/images/sat_tedi_logo.png'
            },
            displayControl: {
              billingAddress: 'HIDE',
              customerEmail: 'HIDE',
              orderSummary: 'SHOW',
              shipping: 'HIDE'
            }
          }
        });
        Checkout.showPaymentPage();

      }else{
        Swal.fire({
          title: 'There is an error with saving the selected details',
        }).then((result) => {
  
        })
      }
    })



    
  }



  userAddressList:UserAddress[] = []
  billingAddress:UserAddress  = {
    type: "",
    addressLineOne: "",
    city: "",
    country: "",
    postalCode: "",
  };

  shippingAddress:UserAddress  = {
    type: "",
    addressLineOne: "",
    city: "",
    country: "",
    postalCode: "",
  };
  listUserAddress(){
    this.userAddressService.findByUser().subscribe(res=>{
        this.userAddressList = res.data;
        if(this.userAddressList.length==0){
          Swal.fire({
            icon: 'warning',
            title: 'Billing and Shipping Addresses Required',
            text: 'Before checkout, you must update your billing and shipping addresses first.',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigate(['account_details/addresses']);
            }
          });
        }

        
        for (let i = 0; i < this.userAddressList.length; i++) {
          const userAddress = this.userAddressList[i];
          if(userAddress.type == 'Billing Address'){
            this.billingAddress = userAddress;
            this.shippingAddress = userAddress;
          }
        }


        this.calculateShoppingTotla()

    })
  }


  isHaveDiscount:boolean = false;
  discountPt:number  = 0;
  calculateShoppingTotla(){
    this.subTotalData =0;
    this.shipping =0;
    this.allTotal_value = 0;

    this.checkoutService.calcuateShippingTotal(this.shippingAddress.postalCode).subscribe(res=>{
      this.userCartDetailsList = res.data;
      
      for (let i = 0; i < this.userCartDetailsList.length; i++) {
        let item = this.userCartDetailsList[i];
        this.discountPt = 0;
        if(item.userEmail=='godfreypy@gmail.com' && item.productId==21){
          this.isHaveDiscount = true;
          this.discountPt = 20;
          this.discount = (item.price/100)*this.discountPt
        }


        if(item.userEmail=='anuradasilva445@gmail.com' && item.productId==21){
          this.isHaveDiscount = true;
          this.discountPt = 20;
          this.discount = (item.price/100)*this.discountPt
        }
        

        //;

        this.subTotalData += item.price;
        this.shipping += item.shippingCost;
        
      }

      this.allTotal_value = (this.subTotalData +this.shipping)-this.discount;
    })
  }





}