<app-navbar-style-one></app-navbar-style-one>
<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<div class="page-title-area" >
    <h1>Address</h1>
    
</div>

<section class="checkout-area" style="padding-top: 20px;">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="billing-details">
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <h3 class="title">Billing Address</h3>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <a routerLink="/add_address/Billing"  class="default-btn"><i class='bx bx-plus'></i>ADD Billing Address</a>
                           
                        </div>
                    </div>
                   
                    <div class="row" *ngFor="let bilingAddress of billingAddressObj">
                        <p>{{bilingAddress.line1}},<br>{{bilingAddress.line2}}, <br>{{bilingAddress.line3}}.<br>{{bilingAddress.postalCode}}<br>{{bilingAddress.country}}<br></p>
                        <a routerLink="/edit_address/billing/{{bilingAddress.id}}"> <h6 style="color: rgba(2, 85, 112, 1);">Edit <i class='bx bx-edit'></i></h6></a>
                        <br><br>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="billing-details">
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <h3 class="title">Shipping Address</h3>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <a routerLink="/add_address/Shipping"  class="default-btn" style="background-color: rgb(67, 67, 238);"><i class='bx bx-plus'></i>ADD Shipping Address</a>
                           
                        </div>
                    </div>
                    
                    <div class="row" *ngFor="let shippingAddress of shippingAddressObj">
                        <div class="col" >
                            <span><input type="checkbox"> <b style="padding-left: 5px;">Select Address</b></span>
                        <p>{{shippingAddress.line1}}, {{shippingAddress.line2}}, {{shippingAddress.line3}}, {{shippingAddress.country}}. {{shippingAddress.postalCode}}</p>
                      
                       <a routerLink="/edit_address/shipping/{{shippingAddress.id}}"> <h6 style="color: rgba(2, 85, 112, 1);">Edit <i class='bx bx-edit'></i></h6></a>
                        <br>
                        </div>
                        
                        
                    </div>  
                </div>
            </div>
        </div>
    </div>
</section>
        