import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BackendURLService {


  // https://tradiov2.web.app/payment/status?resultIndicator=caf7bd005fb54e04&sessionVersion=d2a99fa110
  constructor() { }
 
  apiLocalUrlLocal = "https://tradiov2.uc.r.appspot.com/";
  apiLocalUrl ="https://tradiov2.uc.r.appspot.com/";
  apiCustomerLocalUrl ="https://tradiov2.uc.r.appspot.com/system/admin/";


  //apiLocalUrlLocal = "http://localhost:8080/";
  //apiLocalUrl ="http://localhost:8080/";
  //apiCustomerLocalUrl ="http://localhost:8080/system/admin/";
 
  
}
