import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-navbar-style-two',
    templateUrl: './navbar-style-two.component.html',
    styleUrls: ['./navbar-style-two.component.scss']
})
export class NavbarStyleTwoComponent implements OnInit {
    name = 'Angular';
    public isCollapsed = true;
    token:any;
    constructor() {
        this.token = localStorage.getItem('token');
       
     }

     searchValue: string = '';

     profile(){
        let token = localStorage.getItem('token');
        if(token==null || token=='null'){
            Swal.fire({
                title: 'No account found!',
              }).then((result) => {
                
              }) 
        }else{
            window.location.href = "/account_details";
        }
     }


     searchClicked(){
        Swal.fire({
            title: "Search Product",
            input: "text",
            inputAttributes: {
              autocapitalize: "off"
            },
            showCancelButton: true,
            confirmButtonText: "Search",
            showLoaderOnConfirm: true,
            preConfirm: async (login) => {
                console.log("loginval "+login);  
              try {
                window.location.href= "/search-product/"+login;
              } catch (error) {
                
              }
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            if (result.isConfirmed) {
             /* Swal.fire({
                title: `${result.value.login}'s avatar`,
                imageUrl: result.value.avatar_url
              });*/
            }
          });
     }

     logout(){
        localStorage.setItem('token','empty');
        localStorage.setItem('accShow','notShow');
       
        location.reload();
     }

     accShow:any;
    ngOnInit(): void {
        this.token = localStorage.getItem('token');
        this.accShow = localStorage.getItem('accShow');
        console.log("this.token "+this.token);
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

}