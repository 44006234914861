<app-navbar-style-one></app-navbar-style-one>
<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});height: 200px;">
    <h1 >{{Content.title}}</h1>
</div>




<!-- Start Products Area -->
<section>
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12 col-sm-12">
                <aside class="widget-area left-widget-area">
                    <div class="widget widget_top_rated_products">
                        <h3 class="widget-title">TeDi Smart Items</h3>
                        <div class="scrollable-list">
                            <ul>
                                <li *ngFor="let video of videoList">
                                    <article class="item">
                                        <button (click)="openVideo(video.url)" style="text-align: left;border: none;background-color: white;">
                                            <a class="thumb" (click)="$event.stopPropagation()">
                                                <span class="fullimage cover bg1" role="img">
                                                    <img [src]="video.image">
                                                </span>
                                            </a>
                                            <div class="info">
                                                <h4 class="title usmall">
                                                    <a (click)="openVideo(video.url); $event.stopPropagation()">{{video.title}}</a>
                                                </h4>
                                                <span>By TeDi</span>
                                            </div>
                                        </button>
                                    </article>
                                </li>
                            </ul>
                        </div>
                    </div>
                </aside>
            </div>

            <div class="col-lg-8 col-md-12 col-sm-12">
                <div class="popup">
                    <video  controls (canplay)="pauseVideo(videoplayer)"
                      #videoPlayer class="videoSize">
                    <source src="{{videoSource}}" type="video/mp4">
                    Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Products Area -->