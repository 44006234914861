import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-shop-left-sidebar-page-two',
    templateUrl: './shop-left-sidebar-page-two.component.html',
    styleUrls: ['./shop-left-sidebar-page-two.component.scss']
})
export class ShopLeftSidebarPageTwoComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
       
    }

    pageTitle = [
        {
            bgImage: 'assets/img/privacy-policy-header-bg.jpg',
            title: 'Privacy & Policy'
        }
    ]
    


}