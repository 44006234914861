<app-navbar-style-one></app-navbar-style-one>
<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<!--div class="page-title-area mt-0" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <h1>{{Content.title}}</h1>
    <ul>
        <li><a routerLink="/">Home</a></li>
        <li>{{Content.title}}</li>
    </ul>
</div-->
<!-- End Page Title Area -->


<section class="products-details-area pb-100" style="padding-top: 70px;" >


    <div class="container">

        <div class="row" >
            <div class="col-12 col-sm-12 col-md-6" >
                <div class="products-details-image-slides">
                    <owl-carousel-o [options]="productsDetailsImageSlidesOptions" >
                        <ng-template carouselSlide >
                            <div class="image">
                                <img src="{{selectedProductDetails.productImage1}}" alt="image">
                            </div>
                        </ng-template>
                        <ng-template carouselSlide >
                            <div class="image">
                                <img src="{{selectedProductDetails.productImage2}}" alt="image">
                            </div>
                        </ng-template>
                        <ng-template carouselSlide >
                            <div class="image">
                                <img src="{{selectedProductDetails.productImage3}}" alt="image">
                            </div>
                        </ng-template>
                        <ng-template carouselSlide >
                            <div class="image">
                                <img src="{{selectedProductDetails.productImage4}}" alt="image">
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6" >
                <div class="products-details-desc">
                    <h3 class="product-title" >{{selectedProductDetails.productName}}</h3>
                    <h3 class="product-price">  LKR {{ selectedProductDetails.productPrice | number:'1.2-2' }} <span style="color: red;" *ngIf="selectedProductDetails.sale_type == 2" >(Introductory Price) </span> </h3>
                    <div class="products-meta">
                        <!--span><i class='bx bx-map'></i>Delivery : <span class="sku">{{delivery}}</span></span-->
                        <span *ngIf="category!='Gift Vouchers'"><i class='bx bxs-truck' ></i>Standard Delivery: <span class="in-stock">{{selectedProductDetails.shippingCharge}}</span></span>
                        <span *ngIf="category!='Gift Vouchers'"><i class='bx bxs-calendar' ></i>14 - 21 day(s)</span>
                       <span *ngIf="category=='Gift Vouchers'">To : <input type="text"  [(ngModel)]="recepEmail" placeholder="Enter Email of recipient"></span> 
                        <span><i class='bx bxs-star'></i>{{selectedProductDetails.waranty}}</span>
                        <div [innerHTML]="selectedProductDetails.simpleDescription"></div>
                        <span *ngFor="let info of otherDetails">
                            <div class="row">
                                <div class="col-2">
                                    <p>{{info.type}}</p> 
                                </div>
                                <div class="col-2">
                                    <select (change)="otherDetailsSelected(info.type, $event.target.value)">
                                        <option *ngFor="let item of info.values" [value]="item">{{ item }}</option>
                                      </select>
                                </div>
                                <div class="col-8"></div>
                            </div>
                        </span>
                    </div>
                    <div class="products-add-to-cart">
                        <div class="input-counter">
                            <span class="minus-btn"><i class='bx bx-minus'></i></span>
                            <input type="text" max="3" min="1" value="1" [(ngModel)]="qty" id="quantity" #quantity>
                            <span class="plus-btn"><i class='bx bx-plus'></i></span>
                        </div>
                       <br>
                        <button *ngIf="selectedProductDetails.itemQty > 0"  type="submit" (click)="addToCart(itemId,quantity.value)" class="default-btn" ><i class='bx bxs-cart-add'></i> ADD TO CART</button>
                        <button *ngIf="selectedProductDetails.itemQty == 0"  type="submit" (click)="addToCart(itemId,quantity.value)" class="default-btn btn-warning" ><i class='bx bxs-cart-add'></i> Pre Order</button>
                        <span style="padding-left: 20px;"></span><a type="submit" (click)="addToWishList(itemId)" class="add-to-wishlist-btn" ><i class='bx bx-heart'></i> Add to Wishlist</a>
                    </div>
                   
                    <!--div class="products-share">
                        <ul class="social">
                            <li><span>Share:</span></li>
                            <li><a href="#" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" class="instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div-->
                </div>
            </div>
        </div>

        
       
     <div class="row" >
        <div class="products-details-tabs text-left">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="description-tab" data-bs-toggle="tab" data-bs-target="#description" type="button" role="tab" aria-controls="description" aria-selected="true">Description</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="additional-information-tab" data-bs-toggle="tab" data-bs-target="#additional-information" type="button" role="tab" aria-controls="additional-information" aria-selected="false">Additional Information</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="shipping-tab" data-bs-toggle="tab" data-bs-target="#shipping" type="button" role="tab" aria-controls="shipping" aria-selected="false">Shipping & Returns</button>
                </li>

            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="description" role="tabpanel">
                    <h6 >Product Information</h6>
                    <p [innerHTML]="selectedProductDetails.productDescription"></p>

                </div>
                
                <div class="tab-pane fade" id="additional-information" role="tabpanel">
                    <div class="table-responsive">
                        <h6 >Product Information</h6>
                        <p [innerHTML]="selectedProductDetails.productInformation"></p>
                    </div>
                </div>
                <div class="tab-pane fade" id="shipping" role="tabpanel">
                    <div class="table-responsive">
                        <h6>Delivery & returns</h6>
                        <p>We deliver to Selected countries around the world. For full details of the delivery options we offer, please view our <a href="#">Delivery information</a><br>
                            We hope you’ll love every purchase, but if you ever need to return an item you can do so within a month of receipt. For full details of how to make a return, please view our <a href="#">Returns information</a></p>
                    </div>
                </div>
                <div class="tab-pane fade" id="reviews" role="tabpanel">
                    <div class="products-review-comments">
                        <div class="user-review">
                            <img src="assets/img/user1.jpg" alt="image">
                            <div class="review-rating">
                                <div class="review-stars">
                                    <i class='bx bxs-star checked'></i>
                                    <i class='bx bxs-star checked'></i>
                                    <i class='bx bxs-star checked'></i>
                                    <i class='bx bxs-star checked'></i>
                                    <i class='bx bxs-star checked'></i>
                                </div>
                                <span class="d-inline-block">James Anderson</span>
                            </div>
                            <span class="d-block sub-comment">Excellent</span>
                            <p>Very well built theme, couldn't be happier with it. Can't wait for future updates to see what else they add in.</p>
                        </div>
                    </div>
                    <div class="review-form-wrapper">
                        <h3>Add a review</h3>
                        <p class="comment-notes">Your email address will not be published. Required fields are marked <span>*</span></p>
                        <form>
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="rating">
                                        <input type="radio" id="star5" name="rating" value="5" /><label for="star5"></label>
                                        <input type="radio" id="star4" name="rating" value="4" /><label for="star4"></label>
                                        <input type="radio" id="star3" name="rating" value="3" /><label for="star3"></label>
                                        <input type="radio" id="star2" name="rating" value="2" /><label for="star2"></label>
                                        <input type="radio" id="star1" name="rating" value="1" /><label for="star1"></label>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Name *">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="email" class="form-control" placeholder="Email *">
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea placeholder="Your review" class="form-control" cols="30" rows="6"></textarea>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <p class="comment-form-cookies-consent">
                                        <input type="checkbox" id="test1">
                                        <label for="test1">Save my name, email, and website in this browser for the next time I comment.</label>
                                    </p>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit">SUBMIT</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
     </div>
    </div>
</section>
<!-- End Products Details Area -->