import { Component, OnInit } from '@angular/core';
import { UserloginService } from '../../common_service/userlogin.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-my-account-page',
    templateUrl: './my-account-page.component.html',
    styleUrls: ['./my-account-page.component.scss']
})
export class MyAccountPageComponent implements OnInit {

    constructor(private userlogin:UserloginService) { }

    ngOnInit(): void {
    }

    pageTitle = [
        {
            bgImage: 'assets/img/page-title-bg.jpg',
            title: 'Profile Authentication'
        }
    ]

    lostPasswordCliked:boolean=false;
    lostPassword(){
        if(!this.lostPasswordCliked){
            this.lostPasswordCliked = true;
        }else{
            this.lostPasswordCliked = false;
        }
        
    }

    submitLostPassword(form){
        var email = form.email;
        console.log(email);
        let details = {
            'email':email,
    
        }
        const uploadFormData = new FormData();
        uploadFormData.append('details', JSON.stringify(details));
        this.userlogin.forgotPassword(uploadFormData).subscribe(res=>{
            var status = res.status;
            if(status == 'success'){
                Swal.fire({
                    title: 'Password reset mail has been sent your email address.!',
                  }).then((result) => {
                   
                  })
            }else{
             Swal.fire({
                 title: 'Invalid Email',
            }).then((result) => {
                      
            })
                
            }
        })
    }


    submit(form){
        var email = form.email;
        console.log(email);
        
        var password = form.password;
        console.log(password);

    }


}