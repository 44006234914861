import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackendURLService } from '../admin_service/backend-url.service';
import { User } from '../common/navbar-style-one/UserAccount';
import { UserInfo } from '../pages/gallery-page/account-details/user-info';

@Injectable({
  providedIn: 'root'
})
export class UserloginService {


  constructor(private httpClient:HttpClient,private backendService:BackendURLService) { }

  registerUser(details:User){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'user/register',details);
  }


  validateEmail(token:string){
    //user/verify-account
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'user/verify-account',null,
      {
        headers: new HttpHeaders({
          'Authorization': `${token}`, // Replace 'yourToken' with the actual token variable
          'Content-Type': 'application/json' // Optional, specify if needed
        })
      }
    );
  }


  saveUserInfoChanges(user: UserInfo) {
    let toekn =  localStorage.getItem("token")
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'user/save-changes',user,
      {
        headers: new HttpHeaders({
          'Authorization': `${toekn}`, // Replace 'yourToken' with the actual token variable
          'Content-Type': 'application/json' // Optional, specify if needed
        })
      }
    );
  }
  
  loginUser(details:User){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'user/login',details);
  }

  saveAddress(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'user/saveaddress',details);
  }




  getAddressById(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'user/getaddressbyId',details);
  }

  updateAddress(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'user/updateaddress',details);
  }

  getUserDetails(details:any){
  //  return this.httpClient.post<any>(this.backendService.apiLocalUrl+'user/getuserdetailsforedit',details);
  }

  findUserDetails(){
    let toekn =  localStorage.getItem("token")
    return this.httpClient.get<any>(this.backendService.apiLocalUrl+'user/getuserdetailsforedit',
      {
        headers: new HttpHeaders({
          'Authorization': `${toekn}`, // Replace 'yourToken' with the actual token variable
          'Content-Type': 'application/json' // Optional, specify if needed
        })
      }
    );
  }


  sendOTPNumber(mobileNumber:string){
    let toekn =  localStorage.getItem("token")
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'user/send-otp/'+mobileNumber,null,
      {
        headers: new HttpHeaders({
          'Authorization': `${toekn}`, // Replace 'yourToken' with the actual token variable
          'Content-Type': 'application/json' // Optional, specify if needed
        })
      }
    );
  }


  verifiedOTP(verificationCode:string){
    let toekn =  localStorage.getItem("token")
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'user/verify-otp/'+verificationCode,null,
      {
        headers: new HttpHeaders({
          'Authorization': `${toekn}`, // Replace 'yourToken' with the actual token variable
          'Content-Type': 'application/json' // Optional, specify if needed
        })
      }
    );
  }


  forgotPassword(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrlLocal+'recoverypassword',details);
  }

  updatePassword(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrlLocal+'updatenewpassword',details);
  }
}
