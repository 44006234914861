import { Component } from '@angular/core';

@Component({
  selector: 'app-pre-orders',
  standalone: true,
  imports: [],
  templateUrl: './pre-orders.component.html',
  styleUrl: './pre-orders.component.scss'
})
export class PreOrdersComponent {

}
