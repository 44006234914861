import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UserloginService } from 'src/app/components/common_service/userlogin.service';
import { UserInfo } from './user-info';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-account-details',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule
  ],
  templateUrl: './account-details.component.html',
  styleUrl: './account-details.component.scss'
})
export class AccountDetailsComponent {


  user:UserInfo={
    email:'',
    first_name:'',
    id:0,
    last_name:'',
    mobile:'',
    otpVerifyed:0,
    userId:0
  }


  constructor(private userService:UserloginService){
    this.findUserDetails()
  }



  findUserDetails(){
    this.userService.findUserDetails().subscribe(res=>{
      if(res.status="Success"){
        this.user = res.data
      }
      
    })
  }


  showVerifyOtpDiv: boolean = false;
  sendOTPNumber(){

    this.userService.sendOTPNumber(this.user.mobile).subscribe(res=>{
      Swal.fire({
        title: res.message,
        icon: res.status === 'Success' ? 'success' : 'error'
      });
      this.showVerifyOtpDiv = true;
    })
  }


//verifiedOTP
verificationCode:any = "";
verifyMobileNumber(){

  this.userService.verifiedOTP(this.verificationCode).subscribe(res=>{
    Swal.fire({
      title: res.message,
      icon: res.status === 'Success' ? 'success' : 'error'
    });

    if(res.status == 'Success'){
      this.showVerifyOtpDiv = false;
      this.user.otpVerifyed=1
    }
    
  })
}



saveChanges(){
  this.userService.saveUserInfoChanges(this.user).subscribe(res=>{
    Swal.fire({
      title: res.message,
      icon: res.status === 'Success' ? 'success' : 'error'
    });

    this.findUserDetails();

  })
}


}

