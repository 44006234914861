import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackendURLService } from './backend-url.service';

@Injectable({
  providedIn: 'root'
})
export class ProductServiceService {

  trending_status= 'TRENDING';
  new_status = 'NEW';
  old_status = 'OLD';
  popular_status = 'POPULAR';


  constructor(private httpClient:HttpClient,private backendService:BackendURLService) { }

  saveDetails(details:any){
    var token = localStorage.getItem('token');
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'admin/product/addproduct',details);
  }

  uploadMainImage(details:any,docID:any){
    var token = localStorage.getItem('token');
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'admin/product/addmainproductimage?docId='+docID,details);
  }

  uploadOtherImage(details:any,docID:any){
    var token = localStorage.getItem('token');
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'admin/product/addmainproductotherimage?docId='+docID,details);
  }

  getAllDetails(){
    return this.httpClient.get<any>(this.backendService.apiLocalUrl+'product/getallproduct');
   
  }

  getProductDetailsById(itemId:any){

    return this.httpClient.get<any>(this.backendService.apiLocalUrl+'product/getproductdetilsbyid?id='+itemId);
  }

  getNewProducts(){
    return this.httpClient.get<any>(this.backendService.apiLocalUrl+'product/getnewproducts');
  }

  getNewProductsByStatus(status){
    return this.httpClient.get<any>(this.backendService.apiLocalUrl+'product/getproductsbystatus?status='+status);
  }

  editImage(details:any){
    var token = localStorage.getItem('token');
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'system/admin/product/editproductimage?mds='+token,details);
  }

  editDetails(details:any){
    var token = localStorage.getItem('token');
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'system/admin/product/editproductdetails?mds='+token,details);
  }


  singleProductsItem :Array<any>=[];
  getProductsInTitle(title:any,singleProductsItemList:any){
    this.singleProductsItem = singleProductsItemList;
    const matchingItems = this.singleProductsItem.filter(item => item.title.toLowerCase().includes(title.toLowerCase()));

    if (matchingItems.length === 0) {
        console.log("No matching items found for the provided title.");
        return matchingItems;
    }

    return matchingItems;
  }

  //******************** Software Products */

  saveSoftwareDetails(details:any){
    var token = localStorage.getItem('token');
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'admin/product/addsoftwareproduct',details);
  }

  uploadSoftwareMainImage(details:any,docID:any){
    var token = localStorage.getItem('token');
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'admin/product/addsoftwaremainproductimage?docId='+docID,details);
  }

  uploadSoftwareOtherImage(details:any,docID:any){
    var token = localStorage.getItem('token');
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'admin/product/addsoftwareotherimage?docId='+docID,details);
  }

  getAllSoftwareDetails(){
    return this.httpClient.get<any>(this.backendService.apiLocalUrl+'product/getallsoftwareproduct');
   
  }
}
