import { Component, OnInit } from '@angular/core';
import { AddToCartService } from '../../common_service/add-to-cart.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-cart-page',
    templateUrl: './cart-page.component.html',
    styleUrls: ['./cart-page.component.scss']
})
export class CartPageComponent implements OnInit {

    userCartDetailsList:Array<any> = [];
    userCartDetailsListSize:number=0;
    subTotalData:number= 0;
    shipping:number=0;
    allTotal_value:number=0;
    token:any;
    constructor(private cartService:AddToCartService) { 
     
       
    }

    ngOnInit(): void {
        this.setCartDetails();
    }


    setCartDetails(){
        this.token = localStorage.getItem("token");
        let details = {
            'token':this.token,
          }
        const uploadFormData = new FormData();
        uploadFormData.append('details', JSON.stringify(details));
        this.cartService.getCartDetails(uploadFormData).subscribe(res=>{
            this.userCartDetailsList = res.data;
            this.userCartDetailsListSize = this.userCartDetailsList.length;
            for (let i = 0; i < this.userCartDetailsList.length; i++) {
                let cartItem =  this.userCartDetailsList[i];
                this.subTotalData += cartItem.total;
               
            }
            
            this.allTotal_value += this.subTotalData + this.shipping;
            //this.subTotalData = res.subTotalPrice;
            //this.shipping = res.shipping;
            //this.allTotal_value = res.allTotal_value;
            //console.log("this.userCartDetailsList "+JSON.stringify(this.userCartDetailsList));
        })
    }

    checkoutClicked(){
        console.log("chec " );
        if(this.userCartDetailsList){
            window.location.href = "/checkout";
        }else{
            Swal.fire({
                title:'No items in the cart'
            }).then((result) => {
                    
            })
        }
    }

    pageTitle = [
        {
            bgImage: 'https://tedismart.com/assets/images/TopHeaderAnimation.gif',
            title: 'Shopping Cart'
        }
    ]

    removeItemFromCart(itemId){
        
        this.cartService.removeItem(itemId).subscribe(res=>{
            Swal.fire({
                title: res.message,
                icon: res.status === 'Success' ? 'success' : 'error'
              });

              if(res.status == 'Success'){
                this.setCartDetails();
              }

        })
        
    }

    increaseQuantity(item) {
        item.quantity += 1;
        const uploadFormData = new FormData();
        let itemDetails = {
            'cartDetails':item.cartDetails,
             'quantity':item.quantity,
        }
        uploadFormData.append('details', JSON.stringify(itemDetails));
        this.cartService.changeQuantity(uploadFormData).subscribe(res=>{
            this.userCartDetailsList = res.data;
            this.subTotalData = res.subTotalPrice;
            this.shipping = res.shipping;
            this.allTotal_value = res.allTotal_value;
        })
      }
    
      // Function to decrease the quantity
      decreaseQuantity(item) {
        if (item.quantity > 1) {
          item.quantity -= 1;
          const uploadFormData = new FormData();
          let itemDetails = {
            'cartDetails':item.cartDetails,
             'quantity':item.quantity,
          }
          uploadFormData.append('details', JSON.stringify(itemDetails));
          this.cartService.changeQuantity(uploadFormData).subscribe(res=>{
            console.log("res changeQuantity "+res);
            this.userCartDetailsList = res.data;
            this.subTotalData = res.subTotalPrice;
            this.shipping = res.shipping;
            this.allTotal_value = res.allTotal_value;
          })
         
        }
      }

}