import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackendURLService } from './backend-url.service';

@Injectable({
  providedIn: 'root'
})
export class LoginServiceService {

  constructor(private httpClient:HttpClient,private backendService:BackendURLService) { }

  registerAdmin(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'admin/register',details);
  }

  
  loginAdmin(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'admin/login',details);
  }
}
