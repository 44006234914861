<app-navbar-style-one></app-navbar-style-one>
<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<div class="page-title-area mt-0" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <h1>{{Content.title}}</h1>
   
</div>
<!-- End Page Title Area -->

<section class="contact-area ">
    <div class="container">
        <div class="contact-inner-area">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <h2>Terms and Conditions</h2>
                    <h3>Please read the Terms and Conditions carefully before using TeDiSmart.com.</h3>
                    <p>This is an electronic record in terms of Electronic Transactions Act. No 19 of 2006 and rules thereunder as applicable & the amended provisions pertaining to electronic records in various statutes as amended by the Electronics Transactions Act No. 19 of 2006.
                </p>
                <p>This electronic record is generated by a computer system and does not require any physical or digital signature.</p>
                <br>
                <h3>INTRODUCTION</h3>
                <p>Welcome to TeDiSMart.com also hereby referred as “we", "us" or "TeDi". We are an online market place and these are the terms and conditions governing customers access and use of TeDi along with its related sub-domains and tools (the "Site"). By using the Site, you hereby accept these terms and conditions (including the linked information herein) and represent that you agree to comply with these terms and conditions (the "User Agreement"). This User Agreement is deemed effective upon your use of the Site which signifies your acceptance of these terms. If you do not agree. please do not access, register with or use this Site. This Site is owned and operated by South Asian Technologies Pvt Ltd., (SATL).</p>
                <p>SATL reserves the right to change, modify, add, or remove portions of these Terms and Conditions at any time without any prior notification. Changes will be effective when posted on the Site with no other notice provided. Please check these Terms & Conditions regularly for updates. Your continued use of the Site, following the posting of changes to Terms and Conditions of use constitutes your acceptance of those changes.</p>
                <br>
                <h2>YOUR ACCOUNT</h2>
               
                <p>To access certain services offered by the platform, we may require that you create an account with us or provide personal information to complete the creation of an account. We may at any time in our sole and absolute discretion, invalidate the username and/or password without giving any reason or prior notice and shall not be liable or responsible for any losses suffered by, caused by, arising out of, in connection with or by reason of such request or invalidation.</p>
                <p>We DO NOT save any payment instruments, including credit card information in our site. If prompted to save the credit card information, that prompt will be from the Bank handling the payment gateway, in the payment portal page. We However, strongly recommend not to save any payment instrument information and to only use its details when an order is placed only.</p>
                <p>You are responsible for maintaining the confidentiality of your user identification, password, account details and related private information. You agree to accept this responsibility and ensure your account and its related details are maintained securely at all times and all necessary steps are taken to prevent misuse of your account. You should inform us immediately if you have any reason to believe that your password has become known to anyone else, or if the password is being, or is likely to be, used in an unauthorized manner.</p>
                <p>You agree and acknowledge that any use of the Site and related services offered and/or any access to private information, data or communications using your account and password shall be deemed to be either performed by you or authorized by you as the case may be. You agree to be bound by any access of the Site and/or use of any services offered by the Site (whether such access or use are authorized by you or not). You agree that we shall be entitled (but not obliged) to act upon, rely on or hold you solely responsible and liable in respect thereof as if the same were carried out or transmitted by you.</p>
                <p>You further agree and acknowledge that you shall be bound by and agree to fully indemnify us against any and all losses arising from the use of or access to the Site through your account.</p>
                <p>Please ensure that the details you provide us with are correct and complete at all times. You are obligated to update details about your account in real time by accessing your account online. For pieces of information, you are not able to update by accessing Your Account on the Site, you must inform us via our customer service communication channels to assist you with these changes. We reserve the right to refuse access to the Site, terminate accounts, remove or edit content at any time without prior notice to you. We may at any time in our sole and absolute discretion, request that you update your Personal Data or forthwith invalidate the account or related details without giving any reason or prior notice and shall not be liable or responsible for any losses suffered by or caused by you or arising out of or in connection with or by reason of such request or invalidation. You hereby agree to change your password from time to time and to keep your account secure and also shall be responsible for the confidentiality of your account and liable for any disclosure or use (whether such use is authorised or not) of the username and/or password.</p>

                <h2>PRIVACY</h2>
                <p>Please review our Privacy Agreement by  <a href="https://tradiov2.web.app/privacy_policy">clicking Here.</a> </p>
                <h2>ONLINE PURCHASE</h2>
                <p>You agree, understand and acknowledge that the Site is an online platform that enables you to purchase products listed at the price indicated therein at any time from any location but limited to service areas specifically mentioned on the site, using a payment method of your choice.</p>
                <h2>SITE ACCESS</h2>   
                <p>We require that by accessing the Site, you confirm that you can form legally binding contracts and therefore you confirm that you are at least 18 years of age or are accessing the Site under the supervision of a parent or legal guardian. We grant you a non-transferable, revocable and non- exclusive license to use the Site, in accordance with the Terms and Conditions described herein, for the purposes of shopping for personal items and services as listed to be sold on the Site. Commercial use or use on behalf of any third party is prohibited, except as explicitly permitted by us in advance. If you are registering as a business entity, you represent that you have the authority to bind that entity to this User Agreement and that you and the business entity will comply with all applicable laws relating to online trading. No person or business entity may register as a member of the Site more than once.</p> 
                <p>Any breach of these Terms and Conditions shall result in the immediate revocation of the license granted in this paragraph without notice to you.</p>
                <p>We grant you a limited license to access and make personal use of this Site, but not to download (excluding page caches) or modify the Site or any portion of it in any manner. This license does not include any resale or commercial use of this Site or its contents; any collection and use of any product listings, descriptions, or prices; any derivative use of this Site or its contents; any downloading or copying of account information for the benefit of another seller; or any use of data mining, robots, or similar data gathering and extraction tools.</p>
                <p>This Site or any portion of it (including but not limited to any copyrighted material, trademarks, or other proprietary information) may not be reproduced, duplicated, copied, sold, resold, visited, distributed or otherwise exploited for any commercial purpose without express written consent by us as may be applicable.</p>
                <p>You may not frame or use framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) without our express written consent. You may not use any meta tags or any other text utilizing our name or trademark without our express written consent, as applicable. Any unauthorized use terminates the permission or license granted by us to you for access to the Site with no prior notice. You may not use our logo or other proprietary graphic or trademark as part of an external link for commercial or other purposes without our express written consent, as may be applicable.</p>
                <p>You agree and undertake not to perform restricted activities listed within this section; undertaking these activities will result in an immediate cancellation of your account, services, reviews, orders or any existing incomplete transaction with us and in severe cases may also result in legal action.</p>

                <h2>CLAIMS AGAINST INFRINGING</h2>
                <p>We respect the intellectual property of others. If you believe that your intellectual property rights have been used in a way that gives rise to concerns of infringement, please write to us at <a href="mailto:admin@tedismart.com">admin&#64;tedismart.com</a>  and we will make all reasonable efforts to address your concern within a reasonable amount of time. Please ensure to provide your name, address, contact information and as many relevant details of the claim including name of infringing party, instances of infringement, proof of infringement amongst other.</p>

                <h2>TRADEMARKS / COPYRIGHTS</h2>
                <p>TeDiSmart.com, TeDi logo, and other marks indicated on our Site are trademarks or registered trademarks in the relevant jurisdiction(s). Our graphics, logos, page headers, button icons, scripts and service names are the trademarks or trade dress and may not be used in connection with any product or service that does not belong to us or in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits us. All other trademarks that appear on this Site are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by us.</p>
                <h2>DISCLAIMER</h2>
                <p>You acknowledge and undertake that you are accessing the services on the Site and transacting at your own risk and are using your best and prudent judgment before entering into any transactions through the Site.</p>
                <p>Warranties will differ from product to product based on device type. Please carefully read our Warranty guide for more information on how Warranty is applied and its conditions.</p>
                <h2>COMMUNICATION</h2>
                <p>When you visit tedismart.com, or send e-mails to us via the website, you are communicating with us electronically. You will be required to provide a valid phone number while placing an order with us. We may communicate with you by e-mail, SMS, phone call or by posting notices on the Site or by any other mode of communication we choose to employ. For contractual purposes, you consent to receive communications (including transactional, promotional and/or commercial messages), from us with respect to your use of the website (and/or placement of your order) and agree to treat all modes of communication with the same importance.</p>
                <h2>LOSSES</h2>
                <p>We will not be responsible for any business or personal losses (including but not limited to loss of profits, revenue, contracts, anticipated savings, data, goodwill, or wasted expenditure) or any other indirect or consequential loss that is not reasonably foreseeable to both you and us when you commenced and or while using tedismart.com and or its products.</p>
                <h2>CONDITIONS / ALTERATIONS OF SERVICE</h2>
                <p>We reserve the right to make changes to the Site, its policies, these terms and conditions and any other publicly displayed condition or service promise at any time. You will be subject to the policies and terms and conditions in force at the time you used the Site unless any change to those policies or these conditions is required to be made by law or government authority (in which case it will apply to orders previously placed by you). If any of these conditions is deemed invalid, void, or for any reason unenforceable, that condition will be deemed severable and will not affect the validity and enforceability of any remaining condition.</p>
                <h2>SERVICE DELAYS</h2>
                <p>While every effort is made to ensure a smooth delivery process, We will not be held responsible for any delay or failure to comply with our obligations under these conditions if the delay or failure arises from any cause which is beyond our reasonable control.</p>
                <p>This condition does not affect your statutory rights.</p>
                <h2>WAIVER</h2>
                <p>You acknowledge and recognize that we are a private Company & reserve the right to conduct business to achieve our objectives in a suitable.</p>
                <p>We beg you to acknowledge that if you breach the conditions stated on our Site and we take no action, we are still entitled to use our rights & remedies in any other situation where you breach these conditions.</p>
                <h2>TERMINATION</h2>
                <p>In addition to any other legal or equitable remedies, we may, without prior notice to you, immediately terminate the Terms and Conditions or revoke any or all of your rights granted under the Terms and Conditions. Upon any termination of this Agreement, you shall immediately cease all access to and use of the Site and we shall, in addition to any other legal or equitable remedies, immediately revoke all password(s) and account identification issued to you and deny your access to and use of this Site in whole or in part. Any termination of this agreement shall not affect the respective rights and obligations (including without limitation, payment obligations) of the parties arising before the date of termination. You furthermore agree that the Site shall not be liable to you or to any other person as a result of any such suspension or termination. If you are dissatisfied with the Site or with any terms, conditions, rules, policies, guidelines, or practices in operating the Site, your sole and exclusive remedy is to discontinue using the Site.</p>
                <h2>GOVERNING & JURISDICTION</h2>
                <p>These terms and conditions are governed by and construed in accordance with the laws of The Democratic Socialist Republic of Sri Lanka. You agree, as we do, to submit to the exclusive jurisdiction of the courts, of Sri Lanka having jurisdiction over the place on which our Registered Office is situated at the time of institution of legal proceedings.</p>
                <h2>CONTACT US</h2>
                <p>You may reach us <a href="https://tradiov2.web.app/contact_us" style="color: rgb(15, 189, 246);">Here.</a></p>
            </div>
              
                
            </div>
        </div>
    </div>
</section>
