import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackendURLService } from '../admin_service/backend-url.service';

@Injectable({
  providedIn: 'root'
})
export class VideoGalleryService {

  constructor(private httpClient:HttpClient,private backendService:BackendURLService) { }

  getVideosGallery(){
    return this.httpClient.get<any>(this.backendService.apiLocalUrlLocal+'getvideos');
  }
}
