import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackendURLService } from '../admin_service/backend-url.service';
import { PaymentDetails } from '../pages/checkout-page/PaymentDetails ';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  constructor(private httpClient:HttpClient,private backendService:BackendURLService) { }

  getOrderDetails(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'user/getorderdetailsforcheckout',details);
  }

  getCheckoutToken(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'user/getcheckouttoken',details);
  }

 // updateCheckOutAddress(details:any){
  //  return this.httpClient.post<any>(this.backendService.apiLocalUrl+'user/updatecheckoutaddress',details);
 // }


  getPaymentToken(paymentDetails:PaymentDetails){
    let toekn =  localStorage.getItem("token")
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'user/updatecheckoutaddress',paymentDetails,
      {
        headers: new HttpHeaders({
          'Authorization': `${toekn}`, // Replace 'yourToken' with the actual token variable
          'Content-Type': 'application/json' // Optional, specify if needed
        })
      }
    );
  }

  calcuateShippingTotal(postalCode){

    let toekn =  localStorage.getItem("token")
    return this.httpClient.post<any>(this.backendService.apiLocalUrlLocal+'user/calculate-total/'+postalCode,null,
      {
        headers: new HttpHeaders({
          'Authorization': `${toekn}`, // Replace 'yourToken' with the actual token variable
          'Content-Type': 'application/json' // Optional, specify if needed
        })
      }
    );

  
  }


}
