<!-- Start Main Banner Area -->
<div class="home-slides-two">




    <owl-carousel-o [options]="homeSlidesOptions">
        <ng-template carouselSlide *ngFor="let Content of mainBannerItem;">
            <div class="row align-items-center">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="banner-content">
                       
                        <div class="banner-image" style="padding-top: 0;margin-top: 0;">
                            <img [src]="Content.image" alt="image"  >


                            <div class="carousel-text">
                                <h2>{{Content.title}}</h2>
                                <p>{{Content.shortDescription}}</p>
                                <a routerLink="{{Content.detailsLink}}" class="default-btn"><i class="bx bx-cart"></i> Shop Now</a>
                              </div>
                        
                        
                        
                       

                        </div>

                    </div>
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>
<!-- End Main Banner Area -->