<app-navbar-style-three></app-navbar-style-three>



<section class="profile-authentication-area ptb-20">
    <div class="container">
        <div class="row">
            <div class="col-lg-2 col-md-1 col-sm-1"></div>
            <div class="col-lg-7 col-md-9 col-sm-9">
                <div class="login-form">
                    <h2 style="text-align: center;padding-top: 10px;">Customer Register</h2>

                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)" style="padding-left: 10px;padding-right: 10px;">
                        <!--div class="form-group">
                            <label>First Name</label>
                            <input required minlength="3" maxlength="50" ngModel name="username" type="text" #username="ngModel" class="form-control" id="username" placeholder="Enter Username">  
                        </div-->

                        <div class="form-group row align-items-center">
                            <label class="col-form-label col-sm-2">First Name</label>
                            <div class="col-sm-10">
                                <input 
                                    required 
                                    minlength="3" 
                                    maxlength="50" 
                                    ngModel 
                                    name="firstName"
                                    type="text" 
                                    class="form-control" 
                                    id="firstName" 
                                    placeholder="Enter First Name">
                            </div>
                            
                        </div>

                        <div class="form-group row align-items-center">
                            <label class="col-form-label col-sm-2">Last Name</label>
                            <div class="col-sm-10">
                                <input 
                                    required 
                                    minlength="3" 
                                    maxlength="50" 
                                    ngModel 
                                    name="lastName"
                                    type="text" 
                                    class="form-control" 
                                    id="lastName" 
                                    placeholder="Enter Last Name">
                            </div>
                            
                        </div>  
                        <div class="form-group row align-items-center">
                            <label class="col-form-label col-sm-2">Mobile Number</label>
                            <div class="col-sm-10">
                                <input 
                                    required 
                                    minlength="3" 
                                    maxlength="50" 
                                    ngModel 
                                    name="mobile"
                                    type="text" 
                                    class="form-control" 
                                    id="mobile" 
                                    placeholder="Enter Mobile Number">
                            </div>
                            
                        </div>     
                        <div class="form-group row align-items-center">
                            <label class="col-form-label col-sm-2">Email</label>
                            <div class="col-sm-6">
                                <input 
                                    required 
                                    minlength="3" 
                                    maxlength="50" 
                                    ngModel 
                                    name="email" 
                                    type="text" 
                                    #password="ngModel" 
                                    class="form-control" 
                                    id="email" 
                                    #email
                                    placeholder="Enter Email">
                            </div>
                           
                            <div class="col-sm-4" >
                                <button type="button" style="background-color: rgb(164, 120, 8);"  (click)="requestEmailCode(email.value)" *ngIf="!isRequestEmail">Request Code</button>
                            </div>
                        </div>

                        <div class="form-group row align-items-center" *ngIf="!isVerifyEmail && isRequestEmail">
                            <label class="col-form-label col-sm-4">Email Verification Code</label>
                            <div class="col-sm-8">
                                <input 
                                    required 
                                    minlength="3" 
                                    maxlength="50" 
                                    ngModel 
                                    name="emailCode" 
                                    type="text" 
                                    class="form-control" 
                                    id="emailCode" 
                                    #emailCode
                                    placeholder="Enter Email Verification Code">
                            </div>
                            <!--div class="col-sm-4">
                                <button type="button" (click)="veirfyEmail(email.value,emailCode.value)">Verify Email</button>
                            </div-->
                        </div>

                        <button type="submit" *ngIf="!isSubmitClicked">REGISTER</button>
                    </form>
                </div>
            </div>
            <div class="col-lg-3 col-md-2 col-sm-2"></div>
        </div>
        
       
    </div>
</section>
