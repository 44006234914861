
<div class="navbar-area" >


    <div class="logo">
        <!--a routerLink="/"><img src="assets/img/tedismart_logo.png"  alt="logo" ></a-->
    </div>
    <div class="fiwan-responsive-nav">
        <div class="container">

            <div  >
                
            </div>

            <div class="fiwan-responsive-menu">
                <input class="form-control mr-sm-2" style="width: 80%; margin-left: 100px;" type="search" placeholder="Search" aria-label="Search">
            </div>
        </div>
    </div>

    <div class="fiwan-nav" >
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/tedismart_logo.png" alt="logo" style="height: 40px;"></a>
                <input class="form-control mr-sm-2" style="width: 300px;" name="search_value" [(ngModel)]="searchValue" type="search" placeholder="Search" aria-label="Search">
                <a [routerLink]="['/search-product', searchValue]" class="shop-button2"  ><i class='bx bx-search-alt-2' ></i> Search</a>
                <div class="collapse navbar-collapse mean-menu" style="margin-top: 0px;" >
                    <ul class="navbar-nav"  style="margin-top: 0px;" >
                        <li class="nav-item" style="margin-right: 20px;"><a routerLink="/admin/home" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> <i class='bx bx-home' ></i> Home</a></li>
                        <!--li class="nav-item" style="margin-right: 20px;"> <a routerLink="/admin/add-product" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> <i class='bx bx-shopping-bag'></i> Add Product</a></li>
                        <li class="nav-item" style="margin-right: 20px;"><a routerLink="/admin/add-software-product" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><i class='bx bx-code'></i> Add Software Products</a></li-->
                        <li class="nav-item" style="margin-right: 20px;"><a routerLink="/admin/register/customer" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> <i class='bx bx-user' ></i> Register Customer</a></li>
                        
                        <!--li class="nav-item" style="margin-right: 20px;"><a routerLink="/wishlist" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> <i class='bx bx-diamond'></i> Wishlist</a></li>
                        <li class="nav-item" style="margin-right: 20px;"> <a routerLink="/video-gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> <i class='bx bx-video'></i> Video Gallery</a></li>
                        <li class="nav-item" style="margin-right: 20px;"><a routerLink="/service_support" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> <i class='bx bx-support'></i> Service Support</a></li-->
                      
                        <!--li class="nav-item" style="margin-right: 20px;"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a></li>
                        <li class="nav-item" style="margin-right: 20px;"> <a routerLink="/shop/all" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Shop</a></li>
                        <li class="nav-item" style="margin-right: 20px;"><a routerLink="/cart" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Cart</a></li>
                        <li class="nav-item" style="margin-right: 20px;"><a routerLink="/wishlist" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Wishlist</a></li>
                        <li class="nav-item" style="margin-right: 20px;"> <a routerLink="/video-gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Video Gallery</a></li>
                        <li class="nav-item" style="margin-right: 20px;"><a routerLink="/service_support" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Service Support</a></li>
                        <li class="nav-item" style="margin-right: 20px;"><a routerLink="/software-products" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Software Products</a></li-->
                        <!--li class="nav-item" style="margin-right: 20px;"><a routerLink="/about_us" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li-->
                        <!--li class="nav-item" style="margin-right: 20px;"><a routerLink="/contact_us" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact Us</a></li-->
                        <!--li class="nav-item"><a routerLink="/search-product/all" (click)="searchClicked()" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">  <i class='bx bx-search' style="font-size: 25px;padding-top: 5px;"></i></a></li>
                        <li class="nav-item"  *ngIf="token!=null && accShow=='show'" ><a routerLink="/account_details"  class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">  <i class='bx bx-user' style="font-size: 25px;padding-top: 5px;"></i></a></li>
                        <li class="nav-item" *ngIf="token==null || token=='empty' "><a routerLink="/login" style="padding-left: 5px;color: black;"><img src="assets/img/accountImg.png" style="height: 40px;padding-right: 5px;padding-left: 5px;">Log In</a></li-->
                        <!--li class="nav-item">
                            <form class="d-flex" role="search">
                                <div style="padding-top: 20px;">
                                    <i class="uil uil-search"></i>
                                    <input type="text" placeholder="Search Product" name="search_value" [(ngModel)]="searchValue" style="border-radius: 10px;padding: 5px;"/>
                                    <button class="button" [routerLink]="['/search-product', searchValue]" style="border:none;background: none;">
                                        <i class='bx bx-search' style="font-size: 25px;padding-top: 5px;"></i>
                                    </button>
                                </div>
                            </form>
                        </li-->
                    </ul>
                </div>
            </nav>
        </div>
    </div>  
</div>



<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            
            <div class="search-overlay-close">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>

            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class='bx bx-search'></i></button>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- End Search Overlay -->
 <!--nav class="navbar navbar-expand-lg navbar-dark" style="padding-left: 80px;">
    <div class="container">
      <a class="navbar-brand" href="#"><img id="MDB-logo" src="assets/img/tedismart_logo.png" alt="MDB Logo" draggable="false" style="height: 40px;"/></a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <i class="fas fa-bars"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-3">
          <li class="nav-item">
          
        </ul>
        <form class="d-flex align-items-center w-100 form-search">
          <div class="input-box">
         
            <input type="search" class="form-control" placeholder="Search" aria-label="Search" />
          </div>
          <a href="#!"  style="color: black;"><i class="bx bx-search ps-3"  style="color: black;"></i></a>
        </form>
        <ul class="navbar-nav ms-3">
          <li class="nav-item" *ngIf="token==null || token=='empty' "><a class="nav-link d-flex align-items-center" routerLink="/login"  style="color: black;" >LogIn</a></li>
          <li class="nav-item" *ngIf="token!=null && accShow=='show'"><a class="nav-link d-flex align-items-center me-3" routerLink="/" (click)="profile()" style="color: black;">Account</a></li>
          <li class="nav-item"><a class="nav-link d-flex align-items-center me-3" routerLink="/about_us" style="color: black;">About</a></li>
          <li class="nav-item"><a class="nav-link d-flex align-items-center me-3" routerLink="/about_us" style="color: black;">ContactUs</a></li>
        </ul>
      </div>
    </div>
  </nav>

  <div class="navbar-area" style="background-image: linear-gradient(170deg, rgba(2, 85, 112, 1) 0%, rgba(14, 203, 218, 1) 61%, rgba(0, 255, 119, 1) 100%);">
   

    
    <div class="fiwan-nav" style="padding-right:20px">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
             
                <div class="collapse navbar-collapse mean-menu" >
                    <ul class="navbar-nav" style="padding-left: 0;margin-left: 0;">
                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><i class='bx bx-home'></i>Home</a>
                        </li>
                        <li class="nav-item"> <a routerLink="/shop/all" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><i class='bx bx-shopping-bag'></i>Shop</a>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link"><i class='bx bx-camera'></i>Video Gallery </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/simple-product" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Simple Product</a></li>
                                <li class="nav-item"><a routerLink="/slider-product" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Slider Product</a></li>
                                <li class="nav-item"><a routerLink="/sticky-product" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sticky Product</a></li>
                            </ul>
                        </li>
                        <li class="nav-item"><a routerLink="/service_support" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><i class='bx bx-cog'></i>Service Support</a></li>
                        <li class="nav-item"><a routerLink="/cart" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><i class="bx bx-cart"></i>Cart</a></li>
                        <li class="nav-item"><a routerLink="/wishlist" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><i class='bx bx-heart'></i>Wishlist</a></li>
                        <li class="nav-item"><a routerLink="/software-products" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><i class='bx bx-code'></i>Software Products</a></li>
                       
                    </ul>
                </div>
            </nav>
        </div>
    </div>  
</div-->

<!--div class="row">
    <div class="col-3">

    </div>
    <div class="col-6">
        <div class="input-box" >
            <i class="uil uil-search"></i>
            <input type="text" placeholder="Search Product"  name="search_value" [(ngModel)]="searchValue"/>
            <button class="button" [routerLink]="['/search-product', searchValue]" style="border:none;background: none;"> <i class='bx bx-search'></i></button>
          </div>
    </div>
    <div class="col-3">
        
    </div>
</div-->
   
<!--div class="navbar-area navbar-style-two p-absolute">
    <div class="fiwan-responsive-nav">
        <div class="container">
            <div class="fiwan-responsive-menu">
                <div class="logo">
                    <a routerLink="/"><img src="assets/img/logo.png" alt="logo"></a>
                </div>
            </div>
        </div>
    </div>

    <div class="fiwan-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="logo"></a>

                <div class="collapse navbar-collapse mean-menu">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Home</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Demo - 1</a></li>

                                <li class="nav-item"><a routerLink="/index-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Demo - 2</a></li>

                                <li class="nav-item"><a routerLink="/index-3" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Demo - 3</a></li>
                            </ul>
                        </li>
                        
                        <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Shop</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Shop Full Width <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/shop-full-width-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Full Width Style 01</a></li>

                                        <li class="nav-item"><a routerLink="/shop-full-width-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Full Width Style 02</a></li>
                                    </ul>
                                </li>

                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Shop Left Sidebar <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/shop-left-sidebar-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Left Sidebar Style 01</a></li>

                                        <li class="nav-item"><a routerLink="/shop-left-sidebar-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Left Sidebar Style 02</a></li>
                                    </ul>
                                </li>

                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Shop Right Sidebar <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/shop-right-sidebar-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Right Sidebar Style 01</a></li>

                                        <li class="nav-item"><a routerLink="/shop-right-sidebar-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Right Sidebar Style 02</a></li>
                                    </ul>
                                </li>

                                <li class="nav-item"><a routerLink="/cart" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Cart</a></li>

                                <li class="nav-item"><a routerLink="/checkout" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Checkout</a></li>

                                <li class="nav-item"><a routerLink="/wishlist" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Wishlist</a></li>

                                <li class="nav-item"><a routerLink="/rooms" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Rooms</a></li>

                                <li class="nav-item"><a routerLink="/customer-service" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Customer Service</a></li>

                                <li class="nav-item"><a routerLink="/profile-authentication" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">My Account</a></li>

                                <li class="nav-item"><a routerLink="/order-tracking" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Order Tracking</a></li>
                            </ul>
                        </li>

                        <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Product</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/simple-product" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Simple Product</a></li>

                                <li class="nav-item"><a routerLink="/slider-product" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Slider Product</a></li>

                                <li class="nav-item"><a routerLink="/sticky-product" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sticky Product</a></li>
                            </ul>
                        </li>

                        <li class="nav-item"><a routerLink="/shop-left-sidebar-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Accessories</a></li>

                        <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Pages</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li>

                                <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Gallery</a></li>

                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                                <li class="nav-item"><a routerLink="/error-404" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Error Page</a></li>

                                <li class="nav-item"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coming Soon</a></li>
                            </ul>
                        </li>

                        <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Blog</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog-grid" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Grid</a></li>

                                <li class="nav-item"><a routerLink="/blog-right-sidebar" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Right Sidebar</a></li>
                                        
                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            </ul>
                        </li>
                        
                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact Us</a></li>
                    </ul>

                    <div class="others-option d-flex align-items-center">
                        <div class="option-item">
                            <div class="search-box">
                                <i class='bx bx-search'></i>
                            </div>
                        </div>

                        <div class="option-item">
                            <a routerLink="/cart" class="cart-btn"><i class='bx bx-shopping-bag'></i></a>
                        </div>

                        <div class="option-item">
                            <div class="side-menu-btn">
                                <i class="bx bx-menu-alt-left" data-bs-toggle="modal" data-bs-target="#sidebarModal"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>

    <div class="others-option-for-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>
            
            <div class="container">
                <div class="option-inner">
                    <div class="others-option d-flex align-items-center justify-content-center">
                        <div class="option-item">
                            <div class="search-box">
                                <i class='bx bx-search'></i>
                            </div>
                        </div>

                        <div class="option-item">
                            <a routerLink="/cart" class="cart-btn"><i class='bx bx-shopping-bag'></i></a>
                        </div>

                        <div class="option-item">
                            <div class="side-menu-btn">
                                <i class="bx bx-menu-alt-left" data-bs-toggle="modal" data-bs-target="#sidebarModal"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            
            <div class="search-overlay-close">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>

            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class='bx bx-search'></i></button>
                </form>
            </div>
        </div>
    </div>
</div-->
<!-- End Search Overlay -->