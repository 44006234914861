<app-navbar-style-one></app-navbar-style-one>
<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<div class="page-title-area mt-0" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});height: 200px;">
    <h1 style="color: white;">{{Content.title}}</h1>
   
</div>


<section class="contact-area pt-10">
    <div class="container">
        <div class="contact-inner-area">
            <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12 mt-5">
                    <h1>Contact Information</h1>
                    <p>
                        Our signature Brand, TeDi, equipped with state of the art mobile App, provide our valued customers with NextGen technology in the devices we offer. TeDiSmart devices can be effortlessly integrated to work with other compatible devices in your home or office and fully supports ai voice integration through Amazon Alexa.
                </p>
                   
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 mt-5">
                    <h1>Got Any Questions?</h1>
                    <p>Use the form below to get in touch with the sales team.</p>
                    <div class="contact-form">
                        <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                            <div class="row">
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="form-group mb-3">
                                        <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="Your name">
                                        <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                            <div *ngIf="name.errors.required">Name is required.</div>
                                            <div *ngIf="name.errors.minlength">Name is minimum {{ name.errors.minlength.requiredLength }} character.</div>
                                            <div *ngIf="name.errors.maxlength">Name is maximum 50 character.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="form-group mb-3">
                                        <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="Your email address">
                                        <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email Address is required.</div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="form-group mb-3">
                                        <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number" placeholder="Your phone number">
                                        <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Phone number is required.</div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="form-group mb-3">
                                      <select (change)="serviceType($event)" class="form-control">
                                        <option value="General Service">General Service</option>
                                        <option value="TeDiSmart App Support">TeDiSmart App Support</option>
                                        <option value="TeDi Device Support">TeDi Device Support</option>
                                      </select>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group mb-3">
                                        <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="6" class="form-control" placeholder="Write your message..."></textarea>
                                        <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Message is required.</div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid"><i class='bx bx-paper-plane'></i> SUBMIT</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</section>