import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { UserloginService } from '../../common_service/userlogin.service';
import { User } from './UserAccount';

@Component({
    selector: 'app-navbar-style-one',
    templateUrl: './navbar-style-one.component.html',
    styleUrls: ['./navbar-style-one.component.scss']
})
export class NavbarStyleOneComponent implements OnInit {
  token: any;
  accShow: any;
  searchValue: any;
  constructor(private userlogin: UserloginService) {
    this.token = localStorage.getItem('token');
  }

  isLoginVisible: boolean = false;

  userAccount:User={
    userId:0,
    userName:'',
    password:'',
    accountState:0,
    accountType:'USER'
  }

  registerToSystem(email: string, password: string, confirmPassword: string): void {
    // Check if the passwords match
    if (password !== confirmPassword) {
      Swal.fire({
        title: 'Passwords do not match!',
      })
      return;
    }

    // Basic validation to ensure fields are not empty
    if ( !email || !password) {
      Swal.fire({
        title: 'All fields are required',
      })
      return;
    }

   
    this.userAccount={
      userId:0,
      userName:email,
      password:password,
      accountState:0,
      accountType:''
    }


    this.userlogin.registerUser(this.userAccount).subscribe(res=>{
      Swal.fire({
        title: res.message,
        icon: res.status === 'Success' ? 'success' : 'error'
      });
    })

  }

  showLogin() {
    this.isLoginVisible = true;
  }
  loginToSystem(email: any, password: any) {
    if (email == '') {
      Swal.fire({
        title: 'Please enter email address!',
      }).then((result) => {

      })
    } else if (password == '') {
      Swal.fire({
        title: 'Please enter password!',
      }).then((result) => {

      })
    } else {
      this.userAccount={
        userId:0,
        userName:email,
        password:password,
        accountState:0,
        accountType:''
      }

      this.userlogin.loginUser(this.userAccount).subscribe(res=>{
        if(res.status=='Success'){
          Swal.fire({
            title: 'Success!',
          }).then((result) => {
       
          })
          var token = res.data;
          localStorage.setItem('token', token);
          localStorage.setItem('accShow', 'show');
          window.location.href = "/";
        }else{
          Swal.fire({
            title: res.message,
          }).then((result) => {
            
          })
        }
      });





    }


  }


  submitLostPassword(email) {
    if(email==''){
      Swal.fire({
        title: 'Please enter registered email address!',
      }).then((result) => {
       
      })
    }else{
      console.log(email);
      let details = {
        'email': email,
  
      }
      const uploadFormData = new FormData();
      uploadFormData.append('details', JSON.stringify(details));
      this.userlogin.forgotPassword(uploadFormData).subscribe(res => {
        var status = res.status;
        if (status == 'Success') {
          Swal.fire({
            title: 'Password reset mail has been sent your email address.!',
          }).then((result) => {
  
          })
        } else {
          Swal.fire({
            title: 'Invalid Email',
          }).then((result) => {
  
          })
  
        }
      })
    }

    
   
  }

  ngOnInit(): void {
    this.token = localStorage.getItem('token');
    this.accShow = localStorage.getItem('accShow');
    console.log("this.token " + this.token);
  }

  profile() {
    let token = localStorage.getItem('token');
    if (token == null || token == 'null') {
      Swal.fire({
        title: 'No account found!',
      }).then((result) => {

      })
    } else {
      window.location.href = "/account_details";
    }
  }

  isDropdownOpen = false;
  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

    /*token:any;
    accShow:any;
    searchValue:any;
    constructor() {
        this.token = localStorage.getItem('token');
     }

     
     ngOnInit(): void {
         this.token = localStorage.getItem('token');
         this.accShow = localStorage.getItem('accShow');
         console.log("this.token "+this.token);
     }

     profile(){
      let token = localStorage.getItem('token');
      if(token==null || token=='null'){
          Swal.fire({
              title: 'No account found!',
            }).then((result) => {
              
            }) 
      }else{
          window.location.href = "/account_details";
      }
   }

isDropdownOpen = false;
  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }*/

}