import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackendURLService } from '../admin_service/backend-url.service';

@Injectable({
  providedIn: 'root'
})
export class AddToWishListService {

  constructor(private httpClient:HttpClient,private backendService:BackendURLService) { }

  addToWishList(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'user/addtowishlist',details);
  }

  getWishListDetails(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'user/getwishlistdetails',details);
  }



  removeItemFromWishList(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'user/removeitemfromwishlist',details);

  }
}
