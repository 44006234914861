import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackendURLService } from '../admin_service/backend-url.service';
import { UserAddress } from '../pages/gallery-page/addresses/UserAddress';

@Injectable({
  providedIn: 'root'
})
export class UserAddressService {


  findByUser() {
    let toekn =  localStorage.getItem("token")
    return this.httpClient.get<any>(this.backendService.apiLocalUrlLocal+'user/getaddresses',
      {
        headers: new HttpHeaders({
          'Authorization': `${toekn}`, // Replace 'yourToken' with the actual token variable
          'Content-Type': 'application/json' // Optional, specify if needed
        })
      }
    );
  }

  constructor(private httpClient:HttpClient,private backendService:BackendURLService) { }


  getAddressPostalCode(){
    return this.httpClient.get<any>(this.backendService.apiLocalUrlLocal+'user/get-all-postalcode');
  }



  saveAddress(userAddress:UserAddress){

   let toekn =  localStorage.getItem("token")

  return this.httpClient.post<any>(
  this.backendService.apiLocalUrlLocal + 'user/saveaddress',
  userAddress,
  {
    headers: new HttpHeaders({
      'Authorization': `${toekn}`, // Replace 'yourToken' with the actual token variable
      'Content-Type': 'application/json' // Optional, specify if needed
    })
  }
);
  }



  delete(id: any) {
    let toekn =  localStorage.getItem("token")

    return this.httpClient.post<any>(
    this.backendService.apiLocalUrlLocal + 'user/deleteaddress/'+id,
    null,
    {
      headers: new HttpHeaders({
        'Authorization': `${toekn}`, // Replace 'yourToken' with the actual token variable
        'Content-Type': 'application/json' // Optional, specify if needed
      })
    }
  );
  }


}
