import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserloginService } from '../../common_service/userlogin.service';
import Swal from 'sweetalert2';
import { CheckoutService } from '../../common_service/checkout.service';
import { PaymentService } from '../../common_service/payment.service';

@Component({
  selector: 'app-check-payment-status',
  templateUrl: './check-payment-status.component.html',
  styleUrl: './check-payment-status.component.scss'
})
export class CheckPaymentStatusComponent implements OnInit{

  constructor(private route: ActivatedRoute,private paymentService:PaymentService) { }
  // resultIndicator=53704a2f43c94277&sessionVersion=6ac8755c10
   ngOnInit(): void {
       this.route.queryParamMap.subscribe(params => {
           const resultIndicator = params.get('resultIndicator');
           const sessionVersion = params.get('sessionVersion');
           let details = {
               'session_version':sessionVersion,
               'success_indicator':resultIndicator
             }
             const uploadFormData = new FormData();
             uploadFormData.append('details', JSON.stringify(details));
           this.paymentService.checkpaymentstatus(uploadFormData).subscribe(res=>{
               let status = res.status;
              console.log("pahyment status "+status);

           })
           
         });
   }
  errorContent = [
    {
        img: 'assets/img/email-verify.png',
        title: 'Payment is Being Checked, Please Wait',
        paragraph: 'Please wait while your payment is being verified.'
    }
];
}
