
<div class="section-title" >
    <div class="logo">
        <a routerLink="/"><img src="assets/img/tedismart_logo.png" alt="logo" style="height: 100px;"></a>
    </div>
    <h1 style="padding-top: 20px;">Admin Registration</h1>
    
    
</div>

<section class="order-tracking-area">
    <div class="container">
        <div class="order-tracking-content">
            <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                <div class="form-group">
                    <label>Enter Username</label>
                    <input required minlength="3" maxlength="50" ngModel name="username" type="text" #username="ngModel" class="form-control" id="username" placeholder="Enter Username">
                </div>
                <div class="form-group">
                    <label>Enter Email</label>
                    <input required minlength="3" maxlength="50" ngModel name="email" type="email" #email="ngModel" class="form-control" id="email" placeholder="Enter Email">
                </div>

                <div class="form-group">
                    <label>Enter Password</label>
                    <input required ngModel name="password" type="password" #password="ngModel" class="form-control" id="password" placeholder="Enter Password">
                </div>

                <button type="submit" class="default-btn">REGISTER</button>
            </form>
        </div>
    </div>
</section>
