<!--section class="top-header-area" >
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-10 col-md-6 col-sm-6">
                <img src="assets/img/tedismart_logo.png" alt="logo" class="tediImg" >
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div *ngIf="token==null || token=='empty' "><a routerLink="/login" style="padding-left: 5px;color: black;"><img src="assets/img/accountImg.png" style="height: 40px;padding-right: 5px;padding-left: 5px;">Log In</a></div>
              
                <li *ngIf="token!=null && accShow=='show'" class="nav-item" style="list-style-type: none;">  <a routerLink="/" (click)="profile()"><img src="assets/img/accountImg.png" style="height: 40px;padding-right: 5px;padding-left: 5px;"><span style="color:black;font-weight: bold;">Account</span></a>
               </li>
            </div>
        </div> 
    </div>
</section-->

<div class="navbar-area" >

    <div class="scroller">
        <div class="scroll-text">UNDER REVIEW</div>
      </div>

    <div class="logo">
        <!--a routerLink="/"><img src="assets/img/tedismart_logo.png"  alt="logo" ></a-->
    </div>
    <div class="fiwan-responsive-nav">
        <div class="container">
            <div class="fiwan-responsive-menu">
                <input class="form-control mr-sm-2" style="width: 80%; margin-left: 100px;" type="search" placeholder="Search" aria-label="Search">
            </div>
        </div>
    </div>

    <div class="fiwan-nav" >
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/tedismart_logo.png" alt="logo" style="height: 40px; width: auto; object-fit: contain;"></a>
                <input class="form-control mr-sm-2" style="width: 300px;" name="search_value" [(ngModel)]="searchValue" type="search" placeholder="Search" aria-label="Search">
                <a [routerLink]="['/search-product', searchValue]" class="shop-button2"  ><i class='bx bx-search-alt-2' ></i> Search</a>
                <div class="collapse navbar-collapse mean-menu" style="margin-top: 0px;" >
                    <ul class="navbar-nav"  style="margin-top: 0px;" >
                        <li class="nav-item" style="margin-right: 20px;"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> <i class='bx bx-home' ></i> Home</a></li>
                        <li class="nav-item" style="margin-right: 20px;"> <a routerLink="/shop/all" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> <i class='bx bx-shopping-bag'></i> Shop</a></li>
                        <li class="nav-item" style="margin-right: 20px;"><a routerLink="/cart" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> <i class='bx bx-cart' ></i> Cart</a></li>
                        <li class="nav-item" style="margin-right: 20px;"><a routerLink="/wishlist" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> <i class='bx bx-diamond'></i> Wishlist</a></li>
                        <li class="nav-item" style="margin-right: 20px;"> <a routerLink="/video-gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> <i class='bx bx-video'></i> Video Gallery</a></li>
                        <li class="nav-item" style="margin-right: 20px;"><a routerLink="/service_support" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> <i class='bx bx-support'></i> Service Support</a></li>
                        <li class="nav-item" style="margin-right: 20px;"><a routerLink="/software-products" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><i class='bx bx-code'></i>  Software Products</a></li>
                        <!--li class="nav-item" style="margin-right: 20px;"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a></li>
                        <li class="nav-item" style="margin-right: 20px;"> <a routerLink="/shop/all" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Shop</a></li>
                        <li class="nav-item" style="margin-right: 20px;"><a routerLink="/cart" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Cart</a></li>
                        <li class="nav-item" style="margin-right: 20px;"><a routerLink="/wishlist" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Wishlist</a></li>
                        <li class="nav-item" style="margin-right: 20px;"> <a routerLink="/video-gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Video Gallery</a></li>
                        <li class="nav-item" style="margin-right: 20px;"><a routerLink="/service_support" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Service Support</a></li>
                        <li class="nav-item" style="margin-right: 20px;"><a routerLink="/software-products" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Software Products</a></li-->
                        <!--li class="nav-item" style="margin-right: 20px;"><a routerLink="/about_us" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li-->
                        <!--li class="nav-item" style="margin-right: 20px;"><a routerLink="/contact_us" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact Us</a></li-->
                        <!--li class="nav-item"><a routerLink="/search-product/all" (click)="searchClicked()" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">  <i class='bx bx-search' style="font-size: 25px;padding-top: 5px;"></i></a></li>
                        <li class="nav-item"  *ngIf="token!=null && accShow=='show'" ><a routerLink="/account_details"  class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">  <i class='bx bx-user' style="font-size: 25px;padding-top: 5px;"></i></a></li>
                        <li class="nav-item" *ngIf="token==null || token=='empty' "><a routerLink="/login" style="padding-left: 5px;color: black;"><img src="assets/img/accountImg.png" style="height: 40px;padding-right: 5px;padding-left: 5px;">Log In</a></li-->
                        <!--li class="nav-item">
                            <form class="d-flex" role="search">
                                <div style="padding-top: 20px;">
                                    <i class="uil uil-search"></i>
                                    <input type="text" placeholder="Search Product" name="search_value" [(ngModel)]="searchValue" style="border-radius: 10px;padding: 5px;"/>
                                    <button class="button" [routerLink]="['/search-product', searchValue]" style="border:none;background: none;">
                                        <i class='bx bx-search' style="font-size: 25px;padding-top: 5px;"></i>
                                    </button>
                                </div>
                            </form>
                        </li-->
                    </ul>
                </div>
            </nav>
        </div>
    </div>  
</div>



<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            
            <div class="search-overlay-close">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>

            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class='bx bx-search'></i></button>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- End Search Overlay -->
 <!--nav class="navbar navbar-expand-lg navbar-dark" style="padding-left: 80px;">
    <div class="container">
      <a class="navbar-brand" href="#"><img id="MDB-logo" src="assets/img/tedismart_logo.png" alt="MDB Logo" draggable="false" style="height: 40px;"/></a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <i class="fas fa-bars"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-3">
          <li class="nav-item">
          
        </ul>
        <form class="d-flex align-items-center w-100 form-search">
          <div class="input-box">
         
            <input type="search" class="form-control" placeholder="Search" aria-label="Search" />
          </div>
          <a href="#!"  style="color: black;"><i class="bx bx-search ps-3"  style="color: black;"></i></a>
        </form>
        <ul class="navbar-nav ms-3">
          <li class="nav-item" *ngIf="token==null || token=='empty' "><a class="nav-link d-flex align-items-center" routerLink="/login"  style="color: black;" >LogIn</a></li>
          <li class="nav-item" *ngIf="token!=null && accShow=='show'"><a class="nav-link d-flex align-items-center me-3" routerLink="/" (click)="profile()" style="color: black;">Account</a></li>
          <li class="nav-item"><a class="nav-link d-flex align-items-center me-3" routerLink="/about_us" style="color: black;">About</a></li>
          <li class="nav-item"><a class="nav-link d-flex align-items-center me-3" routerLink="/about_us" style="color: black;">ContactUs</a></li>
        </ul>
      </div>
    </div>
  </nav>

  <div class="navbar-area" style="background-image: linear-gradient(170deg, rgba(2, 85, 112, 1) 0%, rgba(14, 203, 218, 1) 61%, rgba(0, 255, 119, 1) 100%);">
   

    
    <div class="fiwan-nav" style="padding-right:20px">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
             
                <div class="collapse navbar-collapse mean-menu" >
                    <ul class="navbar-nav" style="padding-left: 0;margin-left: 0;">
                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><i class='bx bx-home'></i>Home</a>
                        </li>
                        <li class="nav-item"> <a routerLink="/shop/all" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><i class='bx bx-shopping-bag'></i>Shop</a>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link"><i class='bx bx-camera'></i>Video Gallery </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/simple-product" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Simple Product</a></li>
                                <li class="nav-item"><a routerLink="/slider-product" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Slider Product</a></li>
                                <li class="nav-item"><a routerLink="/sticky-product" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sticky Product</a></li>
                            </ul>
                        </li>
                        <li class="nav-item"><a routerLink="/service_support" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><i class='bx bx-cog'></i>Service Support</a></li>
                        <li class="nav-item"><a routerLink="/cart" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><i class="bx bx-cart"></i>Cart</a></li>
                        <li class="nav-item"><a routerLink="/wishlist" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><i class='bx bx-heart'></i>Wishlist</a></li>
                        <li class="nav-item"><a routerLink="/software-products" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><i class='bx bx-code'></i>Software Products</a></li>
                       
                    </ul>
                </div>
            </nav>
        </div>
    </div>  
</div-->