import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserloginService } from '../../common_service/userlogin.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-account-activation',
  templateUrl: './account-activation.component.html',
  styleUrl: './account-activation.component.scss'
})
export class AccountActivationComponent implements OnInit{
  constructor(private route: ActivatedRoute, private userAccountService:UserloginService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      let token = params.get('token') ?? '';
    
      this.userAccountService.validateEmail(token).subscribe(res=>{
        Swal.fire({
          title: res.message,
          icon: res.status === 'Success' ? 'success' : 'error'
        });
      })

    });
  }
  
  errorContent = [
      {
          img: 'assets/img/email-verify.png',
      title: 'Account Activating',
      paragraph: 'Your account is now being validated. You will be able to start shopping soon!'
      }
  ]
}
