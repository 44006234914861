<div class="tab-pane fade active show" id="tab-password" role="tabpanel" aria-labelledby="tab-password-link">
    <form method="POST">

        <label>Current password </label>
        <input type="password" class="form-control" id="acoount-current-password" name="acoount-current-password">

        <br>
        <br>

        <label>New password </label>
        <input type="password" class="form-control" id="acoount-new-password" name="acoount-new-password">

        <br>
        <br>

        <label>Confirm new password</label>
        <input type="password" class="form-control mb-2" id="acoount-confirm-password" name="acoount-confirm-password">

<br>

        <button type="submit" class="btn btn-outline-success" id="change_password" name="change_password">
            <span>CHANGE PASSWORD</span>
            <i class="icon-long-arrow-right"></i>
        </button>
    </form>
</div>