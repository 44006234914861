import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ProductService } from '../../common_service/product.service';
import Swal from 'sweetalert2';
import { AddToCartService } from '../../common_service/add-to-cart.service';
import { AddToWishListService } from '../../common_service/add-to-wish-list.service';

@Component({
    selector: 'app-popular-products',
    templateUrl: './popular-products.component.html',
    styleUrls: ['./popular-products.component.scss']
})
export class PopularProductsComponent implements OnInit {

    singleProductsBox:Array<any> =[];
    constructor(private productService:ProductService,private addToWishListService:AddToWishListService) {
        productService.getTopSellingProducts().subscribe(res=>{
            this.singleProductsBox = res;
        })    
     }

	 addToWishList(productId:any){
        let token = localStorage.getItem('token');
        console.log("token "+token);
        if(token==null){
            Swal.fire({
                title: 'You need to log in before you add an item!',
              }).then((result) => {
                window.location.href = "/login";
              })
            
        }else{
            let details = {
                'token':token,
                'productId':productId
              }
              const uploadFormData = new FormData();
              uploadFormData.append('details', JSON.stringify(details));
            this.addToWishListService.addToWishList(uploadFormData).subscribe(res=>{
                let status = res.status;
                if(status=="success"){
                    Swal.fire({
                        title: 'Item successfully added to the wish list!',
                      }).then((result) => {
                        
                      })  
                }else{
                    if(status=='AlreadyIn'){
                        Swal.fire({
                            title: 'This product already is in the wish list!',
                          }).then((result) => {
                            
                          }) 
                    }else{
                        Swal.fire({
                            title: 'There is an error with adding to the wish list!',
                          }).then((result) => {
                            
                          })  
                    }
                  
                }
            
            })

        }
        

     }

    ngOnInit(): void {
    }

    sectionTitle = [
        {
            title: "Top Selling Products",
            paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida."
        }
    ]
   

    productsSlidesOptions: OwlOptions = {
		loop: true,
		nav: false,
		dots: true,
		autoplayHoverPause: true,
		autoplay: true,
		margin: 30,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		],
		responsive: {
			0: {
				items: 1
			},
			576: {
				items: 2
			},
			768: {
				items: 2
			},
			992: {
				items: 3
			},
			1200: {
				items: 3
			}
		}
    }

}