import { Component } from '@angular/core';
import Swal from 'sweetalert2';
import { UserloginService } from '../../common_service/userlogin.service';
import { CustomerRegistrationService } from '../../common_service/customer-registration.service';
import { CustomerRegModel } from '../../Modal/CustomerRegModel';

@Component({
  selector: 'app-admin-register-customer',
  templateUrl: './admin-register-customer.component.html',
  styleUrl: './admin-register-customer.component.scss'
})
export class AdminRegisterCustomerComponent {

  constructor(private customerRegistrationService: CustomerRegistrationService) { }

  ngOnInit(): void {
  }

  pageTitle = [
    {
      bgImage: 'assets/img/page-title-bg.jpg',
      title: 'Order Tracking'
    }
  ]

  isVerifyEmail: boolean = false;
  isRequestEmail: boolean = false;
  email:string='';
  customerRegModel!:CustomerRegModel;
  requestEmailCode(email: any) {
    if (email == '') {
      Swal.fire({
        title: 'Please enter email!',
      }).then((result) => {

      })
    } else {
      this.isRequestEmail = true;
     
      this.customerRegModel = new CustomerRegModel('','','',email,'');
      
      this.customerRegistrationService.sendEmailVeirificationCode(this.customerRegModel).subscribe(res => {
        if (res.status == 'Success') {
          Swal.fire({
            title: res.message,
          }).then((result) => {

          })
        }
        if (res.status == 'Error') {
          Swal.fire({
            title: res.message,
          }).then((result) => {

          })
          this.isRequestEmail = false;
        }
      })
    }

  }

  /*veirfyEmail(code:any){
    if(code==''){
      Swal.fire({
        title: 'Please enter email verification code!',
      }).then((result) => {

      })
    }else{
      console.log("code " + code);
    
      this.isVerifyEmail=true;
      this.customerRegModel= new CustomerRegModel('','','',this.email,code);
      this.customerRegistrationService.verifyEmailCode( this.customerRegModel).subscribe(res => {
        if (res.status == 'success') {
          Swal.fire({
            title: res.message,
          }).then((result) => {

          })
        }
        if (res.status == 'error') {
          Swal.fire({
            title: res.message,
          }).then((result) => {

          })
          this.isRequestEmail = false;
          
        }
      })
    }
  }*/


 



isSubmitClicked:boolean=false;
submit(form){
  this.isSubmitClicked=true;
  console.log("submit clicked ");
  var firstName = form.firstName;
  var lastName = form.lastName;
  var email = form.email;
  console.log(email);

  var mobile = form.mobile;
  console.log(mobile);
  var emailCode = form.emailCode;

  if (firstName == '') {
    Swal.fire({
      title: 'Please enter first name!',
    }).then((result) => {

    })
  } else if (lastName == '') {
    Swal.fire({
      title: 'Please enter last name!',
    }).then((result) => {

    })
  } else if (mobile=='') {
    Swal.fire({
      title: 'Please enter mobile number!',
    }).then((result) => {

    })
  } 
  else if (emailCode=='') {
    Swal.fire({
      title: 'Please enter email code!',
    }).then((result) => {

    })
  } 
  else {
    this.customerRegModel = new CustomerRegModel(firstName,lastName,mobile,email,emailCode);
    this.customerRegistrationService.customerRegister(this.customerRegModel).subscribe(res => {
      var status = res.status;
      if (status == 'Success') {
        Swal.fire({
          title: res.message,
        }).then((result) => {
          location.reload();
        })
      } else {
        this.isSubmitClicked=false;
        Swal.fire({
          title: res.message,
        }).then((result) => {
          
        })
        if(res.message=='Email verification code is incorrect!'){
          
        }else{
          this.isRequestEmail = false;
        }
        
      }
    })

  }



}


}
