<app-navbar-style-two></app-navbar-style-two>

<!-- Start 404 Error Area -->
<section class="error-area" style="text-align: center; margin-top: 10px;">
    <div class="container">
        <div class="error-content" *ngFor="let Content of errorContent;">
            <h1>{{Content.title}}</h1>
            <p>{{Content.paragraph}}</p>
            <img [src]="Content.img" alt="image" style="height: 400px;">
            <br>
            <a routerLink="/" class="default-btn"><i class='bx bx-home-circle'></i> BACK TO HOMEPAGE</a>
        </div>
    </div>
</section>