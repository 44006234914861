
<app-navbar-style-three></app-navbar-style-three>

<app-hometwo-banner></app-hometwo-banner>




<!--Top Rated Items-->
<div class="products-area pb-70">
    <app-trending-products></app-trending-products>
</div>

<!--app-partner></app-partner-->

<!--Featured Items-->
<section class="products-area pb-70">
    <app-new-products></app-new-products>
</section>

<!--app-deal-in-this-week></app-deal-in-this-week-->


<!--app-facility-style-two></app-facility-style-two-->

<!--Top Selling Products-->
<div class="products-area pb-70">
    <app-popular-products></app-popular-products>
</div>

<!--app-testimonials></app-testimonials-->

<!--app-blog></app-blog-->

<app-subscribe></app-subscribe>