<app-navbar-style-two></app-navbar-style-two>

<div class="section-title">
    <h2>Add Product Details</h2>

</div>


<section class="contact-area">
    <div class="container">
        <div class="contact-inner-area">

            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="contact-form">
                        <form #contactForm="ngForm">
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <label>Title</label>
                                        <input required minlength="3" maxlength="50" ngModel name="productTitle"
                                            type="text" #productTitle="ngModel" class="form-control" id="productTitle"
                                            placeholder="Product Title">

                                    </div>
                                    <div class="col-lg-4 col-md-4">
                                        <div class="form-group mb-3">
                                            <label>Price</label>
                                            <input required ngModel name="productPrice" type="text" #productPrice="ngModel"
                                                class="form-control" id="productPrice" placeholder="Product Price">
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-lg-8 col-md-8">
                                    <div class="form-group mb-3">
                                        <label>Short Description</label>
                                        <input required ngModel name="productDesciption" type="text"
                                            #productDesciption="ngModel" class="form-control" id="productDesciption"
                                            placeholder="Short Desciption">

                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group mb-3">
                                        <label>Price Value (Ex:11300.50) </label>
                                        <input required ngModel name="productPriceValue" type="text" #productPriceValue="ngModel"
                                            class="form-control" id="productPriceValue" placeholder="Product Price - 11300.50">
                                    </div>
                                </div>
                            </div>
                            <!--br>
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                        <label>Add Main Image</label>
                                        <br>
                                        <input type="file" class="file-input" #fileUploadVehicleReg required accept=".jpg, .jpeg, .png"
                                        (change)="onFileSelectedVehicleReg($event)">
                                    </div>  
                                </div>
                                <div class="col-lg-3 col-md-3">
                                    <div class="form-group mb-3">
                                        <label>Add Other Images</label>
                                        <br>
                                        <input type="file" class="file-input" #fileuploadotherimages required accept=".jpg, .jpeg, .png"
                                        (change)="onFileSelectedOtherImages($event)">
                                        
                                         <ul *ngFor="let Content of otherImagesNameList;" class="reduce-space">
                                            <li [innerHTML]="Content">{{Content}}   </li>
                                        </ul>
                                    </div>  
                                </div>
                                <div class="col-lg-3 col-md-3">
                                    <div class="form-group mb-3">
                                        <button class="default-btn" (click)="addImagesToList(fileuploadotherimages)"><i class='bx bx-plus'></i>Add To Images List</button>
                                    </div>
                                </div>
                            </div-->
                            <br>
                            <div class="row">
                                <div class="col-lg-3 col-md-3">
                                    <div class="form-group mb-3">
                                        <label>Delivery</label>
                                        <input required ngModel name="productDelivery" type="text"
                                            #productDelivery="ngModel" class="form-control" id="productDelivery"
                                            placeholder="Colombo, Sri lanka">
                                    </div>
                                </div>

                                <div class="col-lg-3 col-md-3">
                                    <div class="form-group mb-3">
                                        <label>Standard Delivery</label>
                                        <input required ngModel name="productSTDelivery" type="text"
                                            #productSTDelivery="ngModel" class="form-control" id="productSTDelivery"
                                            placeholder="700.00">
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3">
                                    <div class="form-group mb-3">
                                        <label>Delivery Withing Days (14 -21)</label>
                                        <input required ngModel name="productDeliveryWithin" type="text"
                                            #productDeliveryWithin="ngModel" class="form-control"
                                            id="productDeliveryWithin" placeholder="14 - 21 day(s)">
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3">
                                    <div class="form-group mb-3">
                                        <label>Warrenty</label>
                                        <input required ngModel name="productWarrenty" type="text"
                                            #productWarrenty="ngModel" class="form-control" id="productWarrenty"
                                            placeholder="6 Month warranty">
                                    </div>
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-lg-3 col-md-3">
                                    <div class="form-group mb-3">
                                        <label>Category</label>
                                        <input required ngModel name="productCategory" type="text"
                                            #productCategory="ngModel" class="form-control" id="productCategory"
                                            placeholder="Lighting">
                                    </div>
                                </div>

                                <div class="col-lg-3 col-md-3">
                                    <div class="form-group mb-3">
                                        <label>Add Type (Ex:Colors, Gang )</label>
                                        <input required ngModel name="productOtherTypes" type="text"
                                            [(ngModel)]="productOtherTypes" class="form-control" id="productOtherTypes"
                                            placeholder="Colors">
                                    </div>
                                    <ul *ngFor="let Content of addOtherList;" class="reduce-space">
                                        <li [innerHTML]="Content">{{Content}} </li>
                                    </ul>
                                    <div class="form-group mb-3" *ngIf="addOtherList.length>0">
                                        <button class="default-btn" (click)="doneAdding()">Add Type Details</button>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-2">
                                    <div class="form-group mb-3">
                                        <label>Add Value</label>
                                        <input required ngModel name="productOtherTypesValue" type="text"
                                            [(ngModel)]="productOtherTypesValue" class="form-control"
                                            id="productOtherTypesValue" placeholder="White">
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-2">
                                    <div class="form-group mb-3">
                                        <button class="default-btn"
                                            (click)="addOtherDetails(productOtherTypes,productOtherTypesValue)"><i
                                                class='bx bx-plus'></i>Add</button>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-2">
                                    <table class="table table-striped">
                                        <tbody *ngFor="let highlight of doneOtherDetialsList;">
                                            <tr>
                                                <td>{{highlight.type}}</td>
                                                <td *ngFor="let typeVal of highlight.values;">
                                                    <p>{{typeVal}}</p>
                                                </td>

                                                <td><button (click)="deleteOtherTypes(highlight)"><i
                                                            class='bx bx-trash'></i></button></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <p><b>Add Product Information</b></p>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-lg-8 col-md-8">
                                    <div class="form-group mb-3">
                                        <input required ngModel name="productInformation" type="text"
                                            [(ngModel)]="productInformation" class="form-control"
                                            id="productInformation" placeholder="Add Product Information">
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group mb-3">
                                        <button class="default-btn" (click)="addToProductInformationToList()"><i
                                                class='bx bx-plus'></i>Add To List</button>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <table class="table table-striped">
                                        <tbody *ngFor="let proInfo of productInformationList;">
                                            <tr>
                                                <td>{{proInfo}}</td>
                                                <td><button (click)="deleteProductInfoInList(proInfo)"><i
                                                            class='bx bx-trash'></i></button></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                    <!--ul *ngFor="let Content of productHighlightList;" >
                                        <li [innerHTML]="Content">{{Content}}  </li>
                                    </ul-->
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <p><b>Add Additional Informations</b></p>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group mb-3">
                                        <input required ngModel name="additionInfoType" type="text"
                                            [(ngModel)]="additionInfoType" class="form-control" id="additionInfoType"
                                            placeholder="Add Type">
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group mb-3">
                                        <input required ngModel name="additionInfoValue" type="text"
                                            [(ngModel)]="additionInfoValue" class="form-control" id="additionInfoValue"
                                            placeholder="Add Value">
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group mb-3">
                                        <button class="default-btn" (click)="addSpecification()"><i
                                                class='bx bx-plus'></i>Add Specification</button>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <table class="table table-striped">
                                        <tbody *ngFor="let info of productAdditonalInfoList;">
                                            <tr>
                                                <td>{{info.type}}</td>
                                                <td>{{info.value}}</td>
                                                <td><button (click)="deleteAdditionalInfo(info)"><i
                                                            class='bx bx-trash'></i></button></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" (click)="submit(contactForm.value)" class="default-btn"
                                        [class.disabled]="!contactForm.valid"><i class='bx bx-paper-plane'></i> Save
                                        Product</button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<div class="section-title">
    <h2>Add Product Images</h2>

</div>

<section class="contact-area">
    <div class="container">
        <div class="contact-inner-area">

            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="contact-form">
                        <form #contactForm="ngForm">

                            <div class="row">
                                <div class="col-lg-3 col-md-3">
                                    <div class="form-group mb-3">
                                        <label>Add Main Image</label>
                                        <br>
                                        <input type="file" class="file-input" #fileUploadVehicleReg required
                                            (change)="onFileSelectedMainImg($event)">
                                    </div>

                                </div>
                                <div class="col-lg-3 col-md-3">
                                    <div class="form-group mb-3">
                                        <button class="default-btn" (click)="saveMainImage(fileuploadotherimages)"><i
                                                class='bx bx-plus'></i>Add Main Image</button>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3">
                                    <div class="form-group mb-3">
                                        <label>Add Other Images</label>
                                        <br>
                                        <input type="file" class="file-input" #fileuploadotherimages required
                                             (change)="onFileSelectedOtherImages($event)">

                                        <ul *ngFor="let Content of otherImagesNameList;" class="reduce-space">
                                            <li [innerHTML]="Content">{{Content}} </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3">
                                    <div class="form-group mb-3">
                                        <button class="default-btn"
                                            (click)="addImagesOtherToList(fileuploadotherimages)"><i
                                                class='bx bx-plus'></i>Add To Images List</button>
                                    </div>
                                </div>
                            </div>


                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>