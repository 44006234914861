import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ProductService } from '../../common_service/product.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { AddToCartService } from '../../common_service/add-to-cart.service';
import { AddToWishListService } from '../../common_service/add-to-wish-list.service';

@Component({
	selector: 'app-slider-product-page',
	templateUrl: './slider-product-page.component.html',
	styleUrls: ['./slider-product-page.component.scss']
})
export class SliderProductPageComponent implements OnInit {

	constructor(private route: ActivatedRoute, private productService: ProductService, private addToCartService: AddToCartService,private addToWishListService:AddToWishListService) { }
	itemId: any;
	selectedProductDetails: any;
	images: Array<any> = [];
	title: any;
	price: any;
	shortDescription: any;
	delivery: any;
	stDelivery: any;
	deliveryWithin: any;
	warrenty: any;
	productInfo: any;
	additionalInfo: any;
	otherDetails: any;
	category: any;
	qty:number = 1;
	color:any = "WHITE";
	gang:any = "1 Gang";

	ngOnInit(): void {
		this.route.params.subscribe(params => {
			this.itemId = params['itemId'];
			console.log("product_id " + this.itemId);
			this.productService.getProductDetailsById(this.itemId).subscribe(res => {
				this.selectedProductDetails = res.data;

				this.images = this.selectedProductDetails.otherImages;
				this.title = this.selectedProductDetails.title;
				this.price = this.selectedProductDetails.price;
				this.shortDescription = this.selectedProductDetails.shortDescription;
				this.delivery = this.selectedProductDetails.delivery;
				this.stDelivery = this.selectedProductDetails.standardDelivery;
				this.deliveryWithin = this.selectedProductDetails.deliveryWithin;
				this.warrenty = this.selectedProductDetails.warrenty;
				this.productInfo = this.selectedProductDetails.productInformation;
				this.additionalInfo = this.selectedProductDetails.additionalInformation;
				this.otherDetails = this.selectedProductDetails.otherProductDetails;
				this.category = this.selectedProductDetails.category;
				this.color = res.data.color;
				this.gang = res.data.gang;
				console.info("res.data.color  "+ this.color);
				console.info("res.data.gang "+this.gang);
				//console.log("this.selectedProductDetails " + JSON.stringify(this.selectedProductDetails));

			})
		});

	}

	pageTitle = [
		{
			bgImage: 'assets/img/page-title-bg.jpg',
			title: 'Ergonomic Desk Sofa'
		}
	]

	addToWishList(productId:any){
        let token = localStorage.getItem('token');
        console.log("productId "+productId);
        if(token==null){
            Swal.fire({
                title: 'You need to log in before you add an item!',
              }).then((result) => {
                window.location.href = "/login";
              })
            
        }else{
            let details = {
                'token':token,
                'productId':productId
              }
              const uploadFormData = new FormData();
              uploadFormData.append('details', JSON.stringify(details));
            this.addToWishListService.addToWishList(uploadFormData).subscribe(res=>{
                let status = res.status;
                if(status=="success"){
                    Swal.fire({
                        title: 'Item successfully added to the wish list!',
                      }).then((result) => {
                        
                      })  
                }else{
                    if(status=='AlreadyIn'){
                        Swal.fire({
                            title: 'This product already is in the wish list!',
                          }).then((result) => {
                            
                          }) 
                    }else{
                        Swal.fire({
                            title: 'There is an error with adding to the wish list!',
                          }).then((result) => {
                            
                          })  
                    }
                  
                }
            
            })

        }
        

     }
	

	userSelectedOtherDetails: Array<any> = [];
	otherDetailsSelected(type: any, value: any) {
		console.log("type " + type + " value " + value + " " + this.otherDetails.length);
		for (let i = 0; i < this.otherDetails.length; i++) {
			let de = this.otherDetails[i];
			let ty = de.type;
			let val = de.values;
			console.log("type " + type + " value " + value + " ty " + ty);
			if (ty == type) {
				let obj = {
					'type': ty,
					'value': value
				}
				this.userSelectedOtherDetails.push(obj);
			} else {
				if (val.length == 1) {
					let obj2 = {
						'type': ty,
						'value': val[0]
					}
					this.userSelectedOtherDetails.push(obj2);

				}
			}
		}
		console.log("user " + JSON.stringify(this.userSelectedOtherDetails));
	}

	recepEmail: string = 'NO';
	addToCart(productId: any, quantity: any) {
		
		let token = localStorage.getItem('token');
		console.log("token " + token + " " + (token == null));
		if (token == null || token == 'null') {
			Swal.fire({
				title: 'You need to log in before you add an item!',
			}).then((result) => {
				window.location.href = "/login";
			})

		} else {

			console.info("color "+this.color);
			console.info("gang "+this.gang);

			this.addToCartService.addToCart(productId,this.color, this.gang, quantity).subscribe(res=>{
				Swal.fire({
					title: res.message,
					icon: res.status === 'Success' ? 'success' : 'error'
				  });
			});
		}
	}

	productsDetailsImageSlidesOptions: OwlOptions = {
		loop: true,
		nav: true,
		dots: false,
		autoplayHoverPause: true,
		autoplay: true,
		margin: 30,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		],
		responsive: {
			0: {
				items: 1,
			},
			576: {
				items: 1,
			},
			768: {
				items: 1,
			},
			1200: {
				items: 1,
			}
		}
	}

}