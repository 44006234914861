import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackendURLService } from '../admin_service/backend-url.service';

@Injectable({
  providedIn: 'root'
})
export class AddToCartService {

  constructor(private httpClient:HttpClient,private backendService:BackendURLService) { }

  addToCart(productId:any, pcolor:any, gagn:any, qty:any){
    let toekn =  localStorage.getItem("token")

    let cart={
      'productId':productId,
      'color':pcolor,
      'gagn' :gagn,
      'qty':qty
    }
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'user/addtocart',cart,
      {
        headers: new HttpHeaders({
          'Authorization': `${toekn}`, // Replace 'yourToken' with the actual token variable
          'Content-Type': 'application/json' // Optional, specify if needed
        })
      }
    );
  }

  getCartDetails(details:any){
    let toekn =  localStorage.getItem("token")
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'user/getcartdetails',null, 
      {
        headers: new HttpHeaders({
          'Authorization': `${toekn}`, // Replace 'yourToken' with the actual token variable
          'Content-Type': 'application/json' // Optional, specify if needed
        })
      }
    );
  }

  changeQuantity(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'user/quantitychange',details);

  }

  removeItem(itemId:any){
    let toekn =  localStorage.getItem("token")
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'user/removeitem/'+itemId,null,
      {
        headers: new HttpHeaders({
          'Authorization': `${toekn}`, // Replace 'yourToken' with the actual token variable
          'Content-Type': 'application/json' // Optional, specify if needed
        })
      }
    );

  }



 
}
