<app-navbar-style-one></app-navbar-style-one>
<app-navbar-style-two></app-navbar-style-two>



<div class="page-header text-center" style="background-image: url('assets/img/TopHeaderAnimation.gif');">
    <div class="container"  *ngFor="let Content of pageTitle;" >
        <h1 class="page-title" style="color: #ffecec;">{{Content.title}}</h1>
    </div>
</div>
<nav aria-label="breadcrumb" class="breadcrumb-nav mb-3">
    <div class="container">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="index.php">Home</a></li>
            <li class="breadcrumb-item"><a href="#">Shop</a></li>
            <li class="breadcrumb-item active" aria-current="page">Shopping Cart</li>
        </ol>
    </div><!-- End .container -->
</nav><!-- End .breadcrumb-nav -->



<!-- Start Cart Area -->
<section class="cart-area ptb-100">
    <div class="container">
        <div class="row" *ngIf="userCartDetailsListSize==0">
            <div class="col">
               <h2 style="text-align: center;">No items on the cart.</h2> 
               <br>
               <h4  *ngIf="token==null || token=='empty' " style="text-align: center;"><a routerLink="/login" style="color: chocolate;">Click Here to Login</a></h4>
            </div>
        </div>
        <div class="row" *ngIf="userCartDetailsListSize>0">
            <div class="col-lg-8 col-md-6">
                <form>
                    <div class="cart-table table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">Product</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Unit Price</th>
                                    <th scope="col">Quantity</th>
                                    <th scope="col">Total</th>
                                </tr>
                            </thead>
        
                            <tbody *ngFor="let cartDetails of userCartDetailsList">
                                <tr>
                                    <td class="product-thumbnail">
                                        <a routerLink="/simple-product"><img [src]="cartDetails.imageUrl" alt="item"></a>
                                    </td>
        
                                    <td class="product-name">
                                        <a routerLink="/simple-product">{{cartDetails.productName}}</a>
                                    </td>
        
                                    <td class="product-price">
                                        <span class="unit-amount">{{cartDetails.price | number:'1.2-2'}}</span>
                                    </td>
        
        
                                    <td class="product-quantity">
                                        <span class="minus-btn" (click)="decreaseQuantity(cartDetails)"><i class='bx bx-minus'></i></span>
                                        <input type="text" min="1" [value]="cartDetails.qty" readonly style="width: 30px;border: none;text-align: center;">
                                        <span class="plus-btn" (click)="increaseQuantity(cartDetails)"><i class='bx bx-plus'></i></span>
                                    </td>
        
                                    <td class="product-subtotal">
                                        <span class="subtotal-amount">{{cartDetails.totoalPrice}}</span>
                                        <button type="submit" class="remove" (click)="removeItemFromCart(cartDetails.cartId)"><i class='bx bx-trash'></i></button>
                                     
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
        
                    <!--div class="cart-buttons">
                        <div class="row align-items-center">
                            <div class="col-lg-7 col-sm-12 col-md-7">
                                <div class="shopping-coupon-code">
                                    <input type="text" class="form-control" placeholder="Coupon code" name="coupon-code" id="coupon-code">
                                    <button type="submit">APPLY COUPON</button>
                                </div>
                            </div>
        
                            <div class="col-lg-5 col-sm-12 col-md-5 text-right">
                                <a routerLink="/shop-left-sidebar-1" class="default-btn"><i class='bx bx-refresh'></i> UPDATE CART</a>
                            </div>
                        </div>
                    </div-->
        
                    
                </form>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="cart-totals" style="padding-top: 0;margin-top: 0;">
                    <ul  style="padding-top: 50px;">
                        <li>Subtotal LKR<span>{{subTotalData | number:'1.2-2'}}</span></li>
                        <!--li>Shipping LKR<span>{{shipping | number:'1.2-2'}}</span></li-->
                        <li>Total <span>LKR {{allTotal_value | number:'1.2-2'}}</span></li>
                    </ul>
                    <a  (click)="checkoutClicked()"  class="default-btn"><i class='bx bx-paper-plane'></i> PROCEED TO CHECKOUT</a>
                </div>
            </div>
        </div>
        
    </div>
</section>
<!-- End Cart Area -->