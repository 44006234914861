import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ProductServiceService } from 'src/app/components/admin_service/product-service.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-living-room-products',
    templateUrl: './living-room-products.component.html',
    styleUrls: ['./living-room-products.component.scss']
})
export class LivingRoomProductsComponent implements OnInit {

    constructor(private productService: ProductServiceService) { }

    ngOnInit(): void {
    }

    //*********************Other Details ST ********************************* */
    productOtherTypes: string = '';
    productOtherTypesValue: string = '';
    addOtherList: Array<any> = [];
    currentType: string = '';
    doneOtherDetialsList: Array<any> = [];
    addOtherDetails(type: any, typeVal: any) {
        this.addOtherList.push(typeVal);
        this.currentType = type;
        this.productOtherTypesValue = '';
    }
    doneAdding() {
        let types = {
            'type': this.currentType,
            'values': this.addOtherList
        }
        this.doneOtherDetialsList.push(types);
        this.productOtherTypes = '';
        this.addOtherList = [];
    }

    deleteOtherTypes(type: any) {
        const index = this.doneOtherDetialsList.indexOf(type);
        if (index > -1) {
            this.doneOtherDetialsList.splice(index, 1); // Remove one item at the found index
        }
    }

    //*********************Prodcut Info ********************************* */
    productInformation: string = '';
    productInformationList: Array<any> = [];
    addToProductInformationToList() {
        if (this.productInformation.length == 0) {
            Swal.fire({
                title: 'Please enter product information',
            }).then((result) => {

            })
        } else {
            this.productInformationList.push(this.productInformation);
            this.productInformation = '';
        }
    }

    deleteProductInfoInList(highlight: any) {
        const index = this.productInformationList.indexOf(highlight);
        console.log("delete " + index);
        if (index > -1) {
            this.productInformationList.splice(index, 1);
        }
    }

    //*********************Prodcut Additional Info ********************************* */
    additionInfoType: string = '';
    additionInfoValue: string = '';
    productAdditonalInfoList: Array<any> = [];
    addSpecification() {
        if (this.additionInfoType.length == 0) {
            Swal.fire({
                title: 'Please enter product addional information',
            }).then((result) => {

            })
        } else {
            let spObj = {
                'type': this.additionInfoType,
                'value': this.additionInfoValue
            }
            this.productAdditonalInfoList.push(spObj);
            this.additionInfoType = '';
            this.additionInfoValue = '';
        }
    }

    deleteAdditionalInfo(info: any) {
        const index = this.productAdditonalInfoList.indexOf(info);
        if (index > -1) {
            this.productAdditonalInfoList.splice(index, 1);
        }
    }


    //************** Submit Product Details */
    docId: string = '';
    submit(form) {
        var productTitle = form.productTitle;
        var productDesciption = form.productDesciption;
        var productPrice = form.productPrice;
        var delivery = form.productDelivery;
        var standardDelivery_value = form.productSTDelivery;
        var deliveryWithin = form.productDeliveryWithin;
        var warrenty = form.productWarrenty;
        var category = form.productCategory;
        var price_value = form.productPriceValue;
       var standardDelivery = "LKR "+standardDelivery_value;

        let details = {
            'id': "0",
            'status': 'NEW',
            'title': productTitle,
            'shortDescription': productDesciption,
            'price': productPrice,
            'price_value':price_value,
            'delivery': delivery,
            'standardDelivery_value': standardDelivery_value,
            'standardDelivery':standardDelivery,
            'deliveryWithin': deliveryWithin,
            'warrenty': warrenty,
            'category': category,
            'otherProductDetails': this.doneOtherDetialsList,
            'productInformation': this.productInformationList,
            'additionalInformation': this.productAdditonalInfoList,
            'otherImages': [],
            'detailsLink': 'product_details/',
            

        }

        const uploadFormData = new FormData();
        uploadFormData.append('details', JSON.stringify(details));
        this.productService.saveDetails(uploadFormData).subscribe(res => {
            var status = res.status;
            this.docId = res.doc_id;
            if (status == 'success') {
                Swal.fire({
                    title: 'Successfully added the product!',
                }).then((result) => {
                })
            } else {
                Swal.fire({
                    title: 'Error with adding the product!',
                }).then((result) => {

                })
            }
        })
    }

    //************ Upload Product Images */

    //************* Upload Main Image *********************8 */
    public selectedMainImage: any = File;
    onFileSelectedMainImg(event: any) {
        const file = event.target.files[0];
        this.selectedMainImage = file;
    }

    saveMainImage(fileInput: HTMLInputElement) {
        this.docId = "ru3SxfymUC6lUyUMc4Iz";
        const uploadFormData = new FormData();
        uploadFormData.append('file', this.selectedMainImage);

        if (this.docId.length == 0) {
            Swal.fire({
                title: 'Please save product details to add images!',
            }).then((result) => {
            })
        } else {
            this.productService.uploadMainImage(uploadFormData, this.docId).subscribe(res => {
                var status = res.status;
                if (status == 'success') {
                    Swal.fire({
                        title: 'Successfully added the product main image!',
                    }).then((result) => {
                        fileInput.value = '';
                        // location.reload();
                    })
                } else {
                    Swal.fire({
                        title: 'Error with adding the product!',
                    }).then((result) => {
                        fileInput.value = '';
                        //location.reload();
                    })
                }
            })
        }

    }
    //***********Other Images ***************************** */
    public otherImages: any = File;
    otherImagesList: Array<any> = [];
    otherImagesNameList: Array<any> = [];
    onFileSelectedOtherImages(event: any) {
        const file = event.target.files[0];
        this.otherImages = file;
    }

    addImagesOtherToList(fileInput: HTMLInputElement) {
        const uploadFormData = new FormData();
        uploadFormData.append('file', this.otherImages);

        this.productService.uploadOtherImage(uploadFormData, this.docId).subscribe(res => {
            var status = res.status;
            if (status == 'success') {
                Swal.fire({
                    title: 'Successfully added the product image!',
                }).then((result) => {
                    fileInput.value = '';
                })
            } else {
                Swal.fire({
                    title: 'Error with adding the product!',
                }).then((result) => {
                    fileInput.value = '';
                })
            }
        })

    }



}