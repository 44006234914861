export class CustomerRegModel {
    first_name: string;
    last_name:string;
    email: string;
    email_code: string;
    mobile: string;
   
   
   

    constructor(  
        first_name:string,
        last_name:string,
        mobile: string,
        email: string,
        email_code: string,
       
       
    
          
    ) {
       
       
        this.first_name = first_name;
        this.last_name=last_name;
        this.mobile =mobile;
        this.email = email;
        this.email_code = email_code;
       

    }

  
    getEmail(): string {
        return this.email;
    }
    setEmail(email: string): void {
        this.email = email;
    }

    getEmail_code(): string {
        return this.email_code;
    }
    setEmail_code(email_code: string): void {
        this.email_code = email_code;
    }
   
    getMobile(): string {
        return this.mobile;
    }
    setMobile(mobile: string): void {
        this.mobile = mobile;
    }

    

    getFirst_name(): string {
        return this.first_name;
    }
    setFirst_name(first_name: string): void {
        this.first_name = first_name;
    }

    getLast_name(): string {
        return this.last_name;
    }
    setLast_name(last_name: string): void {
        this.last_name = last_name;
    }
   

}
