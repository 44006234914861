<app-navbar-style-one></app-navbar-style-one>
<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<!--div class="page-title-area" *ngFor="let Content of pageTitle;" >
    <h1>{{Content.title}}</h1>
</div-->
<!-- End Page Title Area -->

<!-- Start Profile Authentication Area -->
<section class="profile-authentication-area ptb-20">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-2 col-sm-2"></div>
            <div class="col-lg-6 col-md-8 col-sm-8">
                <div class="login-form">
                    <h2 style="text-align: center;">Register</h2>

                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)" style="padding-left: 10px;padding-right: 10px;">
                        <div class="form-group">
                            <label>Username</label>
                            <input required minlength="3" maxlength="50" ngModel name="username" type="text" #username="ngModel" class="form-control" id="username" placeholder="Enter Username">
                        </div>

                        <div class="form-group">
                            <label>Email</label>
                            <input required minlength="3" maxlength="50" ngModel name="email" type="email" #email="ngModel" class="form-control" id="email" placeholder="Enter Email">
                        </div>

                        <div class="form-group">
                            <label>Password</label>
                            <input required minlength="3" maxlength="50" ngModel name="password" type="password" #password="ngModel" class="form-control" id="password" placeholder="Enter Password">
                        </div>

                        <div class="form-group">
                            <label>Confirm Password</label>
                            <input required minlength="3" maxlength="50" ngModel name="confirmpassword" type="password" #confirmpassword="ngModel" class="form-control" id="confirmpassword" placeholder="Confirm Password">
                        </div>

                        <!--p class="description">The password should be at least eight characters long. To make it stronger, use upper and lower case letters, numbers, and symbols like ! " ? $ % ^ & )</p-->

                        <button type="submit">REGISTER</button>
                    </form>
                </div>
            </div>
            <div class="col-lg-3 col-md-2 col-sm-2"></div>
        </div>
        
        <!--div class="products-details-tabs text-left">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="description-tab" data-bs-toggle="tab" data-bs-target="#description" type="button" role="tab" aria-controls="description" aria-selected="true">Login</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="additional-information-tab" data-bs-toggle="tab" data-bs-target="#additional-information" type="button" role="tab" aria-controls="additional-information" aria-selected="false">Register</button>
                </li>
                
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="description" role="tabpanel">
                    <div class="login-form">
                        <h2>Login</h2>
    
                        <form>
                            <div class="form-group">
                                <label>Username or email</label>
                                <input type="text" class="form-control" placeholder="Username or email">
                            </div>
    
                            <div class="form-group">
                                <label>Password</label>
                                <input type="password" class="form-control" placeholder="Password">
                            </div>
    
                            <div class="row align-items-center">
                                <div class="col-lg-6 col-md-6 col-sm-6 remember-me-wrap">
                                    <p>
                                        <input type="checkbox" id="test2">
                                        <label for="test2">Remember me</label>
                                    </p>
                                </div>
    
                                <div class="col-lg-6 col-md-6 col-sm-6 lost-your-password-wrap">
                                    <a routerLink="/" class="lost-your-password">Lost your password?</a>
                                </div>
                            </div>
    
                            <button type="submit">LOG IN</button>
                        </form>
                    </div>
                </div>
                
                <div class="tab-pane fade" id="additional-information" role="tabpanel">
                    <div class="table-responsive">
                        <div class="register-form">
                            <h2>Register</h2>
        
                            <form>
                                <div class="form-group">
                                    <label>Username</label>
                                    <input type="text" class="form-control" placeholder="Username">
                                </div>
        
                                <div class="form-group">
                                    <label>Email</label>
                                    <input type="email" class="form-control" placeholder="Email">
                                </div>
        
                                <div class="form-group">
                                    <label>Password</label>
                                    <input type="password" class="form-control" placeholder="Password">
                                </div>
        
                                <div class="form-group">
                                    <label>Confirm Password</label>
                                    <input type="password" class="form-control" placeholder="Password">
                                </div>
        
                                <p class="description">The password should be at least eight characters long. To make it stronger, use upper and lower case letters, numbers, and symbols like ! " ? $ % ^ & )</p>
        
                                <button type="submit">REGISTER</button>
                            </form>
                        </div>
                    </div>
                </div>
                
                
            </div>
        </div-->
        <!--div class="row">
            <div class="col-lg-6 col-md-12">
                
            </div>
            
            <div class="col-lg-6 col-md-12">
                
            </div>
        </div-->
    </div>
</section>
<!-- Start Profile Authentication Area -->

<!--div class="page-title-area mt-0" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <h1>{{Content.title}}</h1>
    <ul>
        <li><a routerLink="/">Home</a></li>
        <li>{{Content.title}}</li>
    </ul>
</div>

<section class="order-tracking-area ptb-100">
    <div class="container">
        <div class="order-tracking-content">
            <form>
                <div class="form-group">
                    <label>Order ID</label>
                    <input type="text" class="form-control" placeholder="Found in your order confirmation email">
                </div>

                <div class="form-group">
                    <label>Billing E-mail</label>
                    <input type="email" class="form-control" placeholder="Email you used during checkout">
                </div>

                <button type="submit" class="default-btn">TRACK ORDER</button>
            </form>
        </div>
    </div>
</section-->