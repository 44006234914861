import { Component, OnInit } from '@angular/core';
import { AddToWishListService } from '../../common_service/add-to-wish-list.service';
import Swal from 'sweetalert2';
import { AddToCartService } from '../../common_service/add-to-cart.service';

@Component({
    selector: 'app-wishlist-page',
    templateUrl: './wishlist-page.component.html',
    styleUrls: ['./wishlist-page.component.scss']
})
export class WishlistPageComponent implements OnInit {

    userWishList:Array<any> = [];
    userWishListSize:number=0;
    constructor(private wishlistservice:AddToWishListService,private addToCartService:AddToCartService) { 
        let token = localStorage.getItem("token");
        let details = {
            'token':token,
          }
        const uploadFormData = new FormData();
        uploadFormData.append('details', JSON.stringify(details));
        this.wishlistservice.getWishListDetails(uploadFormData).subscribe(res=>{
            this.userWishList = res.data;
            this.userWishListSize = this.userWishList.length;
           
            console.log("this.userCartDetailsList "+JSON.stringify(this.userWishList));
        })
       
    }


    addToCart(productId:any,quantity:any){
        let token = localStorage.getItem('token');
        console.log("token "+token + " "+quantity);
        if(token==null){
            Swal.fire({
                title: 'You need to log in before you add an item!',
              }).then((result) => {
                window.location.href = "/login";
              })
            
        }else{
            let details = {
                'token':token,
                'productId':productId+'',
				'quantity':quantity+''
              }
              const uploadFormData = new FormData();
              uploadFormData.append('details', JSON.stringify(details));
          
        }
     }

     removeItemFromWishList(item){
        const uploadFormData = new FormData();
        let itemDetails = {
            'cartDetails':item.cartDetails,
        }
        uploadFormData.append('details', JSON.stringify(itemDetails));
        this.wishlistservice.removeItemFromWishList(uploadFormData).subscribe(res=>{
            let status = res.status;
            if(status=='success'){
                Swal.fire({
                    title: 'Successfully removed from the wish list!',
                  }).then((result) => {
                    
                  })
            }else{
                Swal.fire({
                    title: 'There is an issue with removing from the wish list!',
                  }).then((result) => {
                    
                  }) 
            }
            let dataObj = res.data;
            this.userWishList = dataObj.data;
          
        })
    }

    ngOnInit(): void {
    }

    pageTitle = [
        {
            bgImage: 'https://tedismart.com/assets/images/TopHeaderAnimation.gif',
            title: 'Wishlist'
        }
    ]

}