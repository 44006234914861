import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-shop-full-width-page-two',
    templateUrl: './shop-full-width-page-two.component.html',
    styleUrls: ['./shop-full-width-page-two.component.scss']
})
export class ShopFullWidthPageTwoComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
       
    }

    pageTitle = [
        {
            bgImage: 'assets/img/terms-conditions-header-bg.jpg',
            title: 'Terms of Use'
        }
    ]
   

}